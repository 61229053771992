import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const ContactPageInstructions = () => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#000" }} />}>
      <Typography sx={{ display: "flex", gap: 1, fontWeight: 600 }}>
        <HelpOutlineIcon />
        Jak dodać Google Maps URL ?
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>
        <p style={{ display: "flex", gap: 10 }}>
          <RadioButtonCheckedIcon />
          Wejdz na stronę
          <Link
            href="https://www.google.pl/maps"
            target="_blank"
            sx={{
              color: "#000",
              textDecorationColor: "#000",
              "&:hover": {
                color: "#337ab7",
              },
            }}
          >
            Google Maps
          </Link>
        </p>
        <p style={{ display: "flex", gap: 10 }}>
          <RadioButtonCheckedIcon />
          Wpisz swoją lokalizację
        </p>
        <p style={{ display: "flex", gap: 10 }}>
          <RadioButtonCheckedIcon />
          Naciśnij udostępnij i przejdź do zakładki uemiszczenie mapy
        </p>
        <img
          src="/images/AdminPage/ContactPage/image3.jpg"
          alt="Info"
          style={{
            width: "100%",
          }}
        />
        <p style={{ display: "flex", gap: 10 }}>
          <RadioButtonCheckedIcon />
          Skopiuj wartość src w wygenerowanym linku pokazny na poniższym zdjęciu
        </p>
        <img
          src="/images/AdminPage/ContactPage/image4.jpg"
          alt="Info"
          style={{
            width: "100%",
          }}
        />
        <p style={{ display: "flex", gap: 10 }}>
          <RadioButtonCheckedIcon />
          Wklej wartość do pola Google Map URL w panelu administratora
        </p>
      </Typography>
    </AccordionDetails>
  </Accordion>
);

export default ContactPageInstructions;
