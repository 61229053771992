import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Pagination,
  Box,
  Divider,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import useIsMobile from "../../Assets/useIsMobile";
import axios from "axios";
import { endpoint } from "../../endpoint";
import { SpinnerDotted } from "spinners-react";
import { useSpring, animated } from "@react-spring/web";
import FilePageStyles from "./FilePageStyles";

const formatFileSize = (size) => {
  if (size < 1024) return `${size} B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
};

const FilesPage = () => {
  const classes = FilePageStyles();
  const { isXs, isSm } = useIsMobile();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const [downloading, setDownloading] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    fetchFiles();
    window.scrollTo(0, 0);
  }, [page]);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${endpoint.baseUrl}/files/show-true`);
      setFiles(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching files", error);
      setLoading(false);
    }
  };

  const handleDownload = async (id) => {
    setDownloading(id);
    try {
      const response = await axios.get(
        `${endpoint.baseUrl}/files/download-file/${id}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        files.find((file) => file.id === id).filename
      );
      document.body.appendChild(link);
      link.click();
      setSnackbarMessage("Plik został pobrany pomyślnie");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error downloading file", error);
      setSnackbarMessage("Błąd podczas pobierania pliku");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setDownloading(null);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedFiles = files.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const slideBottom = useSpring({
    opacity: loading ? 0 : 1,
    transform: loading ? "translateY(30%)" : "translateY(0px)",
    from: { opacity: 0, transform: "translateY(30%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <SpinnerDotted size={90} color="#a9ffff" />
        </div>
      )}
      <animated.div style={{ ...slideBottom, display: loading ? "none" : "" }}>
        <Container className={isXs || isSm ? classes.rootMobile : classes.root}>
          <Typography variant="h4" className={classes.typography}>
            Lista plików
          </Typography>
          <Box
            sx={{
              p: isXs || isSm ? 1 : 5,
              mt: isXs || isSm ? 5 : 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#ffffffa8",
              borderRadius: 7,
              boxShadow: "0px 0px 5px 2px rgb(245 245 245 / 15%)",
              maxWidth: 900,
              width: "100%",
              color: "#fff",
              background: "linear-gradient(180deg, #03090ede, #8a8a8a21)",
            }}
          >
            <List sx={{ maxWidth: 900, width: "100%" }}>
              {paginatedFiles.map((file) => (
                <ListItem key={file.id} className={classes.listItem}>
                  <ListItemText
                    primary={file.filename}
                    secondary={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                        }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            color: "#afafaf",
                            wordBreak: "break-word",
                            paddingRight: 5,
                          }}
                        >
                          {file.description}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            color: "#ffffffa8",
                            fontSize: 12,
                          }}
                        >
                          {formatFileSize(file.size)}
                        </Typography>
                      </div>
                    }
                    primaryTypographyProps={{
                      style: { color: "#fff", wordBreak: "break-word" },
                    }}
                    sx={{
                      justifyContent: "space-between",
                    }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Divider
                      orientation="vertical"
                      style={{ height: 40, borderColor: "#ffffff7a" }}
                    />
                    <IconButton
                      edge="end"
                      aria-label="download"
                      onClick={() => handleDownload(file.id)}
                      disabled={downloading}
                    >
                      {downloading === file.id ? (
                        <CircularProgress size={24} style={{ color: "#fff" }} />
                      ) : (
                        <DownloadIcon style={{ color: "#fff" }} />
                      )}
                    </IconButton>
                  </div>
                </ListItem>
              ))}
            </List>
            <Pagination
              count={Math.ceil(files.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#ffffff8c",
                },
                " .Mui-selected": {
                  color: "#fff",
                  fontWeight: 700,
                },
              }}
            />
          </Box>
        </Container>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </animated.div>
    </>
  );
};

export default FilesPage;
