import React, { useState } from "react";
import { Box, Button, IconButton, Paper } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { endpoint } from "../../../../endpoint";
import axiosAuth from "../../../../api/axiosConfig";
import ConfirmDelete from "../components/ConfirmDelete";

const ImageSection = ({
  isXs,
  isSm,
  imageData,
  setImageData,
  setSnackbarMessage,
  setSnackbarOpen,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageData({
        file: file,
        imagePath: imageUrl,
      });

      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await axiosAuth.post(
          `${endpoint.baseUrl}/aboutUsImage`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setImageData({
          ...response.data,
          imagePath: `${response.data.imagePath.replace(/\\/g, "/")}`,
        });
        setSnackbarMessage({
          text: "Obraz został dodany",
          severity: "success",
        });
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Błąd przy zapisywaniu obrazu", error);
        setSnackbarMessage({
          text: "Błąd przy zapisywaniu obrazu",
          severity: "error",
        });
        setSnackbarOpen(true);
      }
    }
  };

  const handleRemoveImage = async () => {
    if (imageData) {
      try {
        await axiosAuth.delete(`${endpoint.baseUrl}/aboutUsImage`);
        setImageData(null);
        setSnackbarMessage({
          text: "Obraz został usunięty",
          severity: "success",
        });
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error removing image", error);
        setSnackbarMessage({
          text: "Błąd przy usuwaniu obrazu",
          severity: "error",
        });
        setSnackbarOpen(true);
      }
    }
    setConfirmOpen(false);
  };

  return (
    <Paper
      sx={{
        mb: 4,
        width: isXs || isSm ? "100%" : 600,
        textAlign: "center",
        padding: isXs || isSm ? 2 : 4,
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          alignSelf: "center",
        }}
      >
        {imageData ? (
          <>
            <img
              src={
                imageData.imagePath && imageData.imagePath.includes("blob:")
                  ? imageData.imagePath
                  : `${endpoint.baseUrl}/${imageData.imagePath.replace(
                      /\\/g,
                      "/"
                    )}`
              }
              alt="Logo"
              style={{
                width: isXs || isSm ? "100%" : 350,
                objectFit: "cover",
                borderRadius: 20,
              }}
            />
            <IconButton
              onClick={() => setConfirmOpen(true)}
              sx={{
                position: "absolute",
                top: 10,
                right: 35,
                backgroundColor: "#fff",
                color: "#000",
                padding: "2px",
                transition: "transform 0.3s ease-in-out !important",
                "&:hover": {
                  backgroundColor: "#fff !important",
                  color: "#000 !important",
                  transform: "scale(0.9)",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <Button
            component="label"
            sx={{ padding: 2, border: "2px #000 solid", color: "#000" }}
          >
            <AddPhotoAlternateIcon />
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
        )}
      </Box>

      <ConfirmDelete
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleRemoveImage}
        title="Czy na pewno chcesz usunąć obraz?"
      />
    </Paper>
  );
};

export default ImageSection;
