import React, { useEffect, useState } from "react";
import { Alert, Container, Snackbar } from "@mui/material";
import useIsMobile from "../../../../Assets/useIsMobile";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import axios from "axios";
import { endpoint } from "../../../../endpoint";
import ImageSection from "./ImageSection";
import DescriptionSection from "./DescriptionSection";
import { SpinnerDotted } from "spinners-react";

const AboutPageAdmin = () => {
  const { isXs, isSm } = useIsMobile();
  const [aboutData, setAboutData] = useState({ images: [], sections: [] });
  const [imageData, setImageData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState({
    text: "",
    severity: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseSections = await axios.get(`${endpoint.baseUrl}/aboutUs`);
        setAboutData({
          ...aboutData,
          sections: [
            ...responseSections.data,
            { title: "", content: [""], isNew: true },
          ],
        });

        const responseImage = await axios.get(
          `${endpoint.baseUrl}/aboutUsImage`
        );
        if (responseImage.data && responseImage.data[0].imagePath) {
          setImageData(responseImage.data[0]);
        } else {
          setImageData(null);
        }
      } catch (error) {
        console.error("There was an error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <SpinnerDotted size={90} color="#a9ffff" />
      </div>
    );
  }

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: isXs || isSm ? 1 : 4,
      }}
    >
      <InfoAdmin
        text="Zdjęcie"
        imagePath="/images/AdminPage/AboutPage/image1.jpg"
        imageWidth={isXs || isSm ? "90%" : 600}
      />
      <ImageSection
        isXs={isXs}
        isSm={isSm}
        imageData={imageData}
        setImageData={setImageData}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
      />
      <InfoAdmin
        text="Opisy"
        imagePath="/images/AdminPage/AboutPage/image2.jpg"
        imageWidth={isXs || isSm ? "90%" : 600}
      />
      <DescriptionSection
        isXs={isXs}
        isSm={isSm}
        aboutData={aboutData}
        setAboutData={setAboutData}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarMessage.severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage.text}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AboutPageAdmin;
