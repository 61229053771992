import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveIcon from "@mui/icons-material/Save";
import axiosAuth from "../../../../api/axiosConfig";
import { endpoint } from "../../../../endpoint";
import ConfirmDelete from "../components/ConfirmDelete";

const ComputerServiceSection = ({
  services,
  setServices,
  newService,
  setNewService,
  fetchServices,
  isSm,
  isXs,
  showSnackbar,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [changes, setChanges] = useState({});

  const validateService = (service) => {
    if (!service.name) {
      showSnackbar("Nazwa nie może być pusta", "error");
      return false;
    }
    if (!service.priceRange) {
      showSnackbar("Zakres cen nie może być pusty", "error");
      return false;
    }
    return true;
  };

  const handleServiceChange = (id, field, value) => {
    const updatedServices = services.map((service) => {
      if (service.id === id) {
        return { ...service, [field]: value };
      }
      return service;
    });
    setServices(updatedServices);

    setChanges((prevChanges) => ({
      ...prevChanges,
      [id]: true,
    }));
  };

  const handleAddNewService = async () => {
    if (!validateService(newService)) return;
    try {
      await axiosAuth.post("/quotationList", newService);
      fetchServices();
      showSnackbar("Dodano nową usługę!", "success");
      setNewService({ name: "", priceRange: "" });
    } catch (error) {
      console.error("Błąd podczas dodawania usługi:", error);
      showSnackbar("Błąd podczas dodawania usługi", "error");
    }
  };

  const handleUpdateService = async (id, updatedService) => {
    if (!validateService(updatedService)) return;
    try {
      await axiosAuth.put(
        `${endpoint.baseUrl}/quotationList/${id}`,
        updatedService
      );
      fetchServices();
      showSnackbar("Zaktualizowano usługę!", "success");
      setChanges((prevChanges) => ({
        ...prevChanges,
        [id]: false,
      }));
    } catch (error) {
      console.error("Błąd podczas aktualizacji usługi:", error);
      showSnackbar("Błąd podczas aktualizacji usługi", "error");
    }
  };

  const handleDeleteService = async (id) => {
    try {
      await axiosAuth.delete(`${endpoint.baseUrl}/quotationList/${id}`);
      fetchServices();
      showSnackbar("Usunięto usługę!", "success");
    } catch (error) {
      console.error("Błąd podczas usuwania usługi:", error);
      showSnackbar("Błąd podczas usuwania usługi", "error");
    }
    setOpenDeleteModal(false);
  };

  const openDeleteConfirmation = (service) => {
    setServiceToDelete(service);
    setOpenDeleteModal(true);
  };

  const closeDeleteConfirmation = () => {
    setServiceToDelete(null);
    setOpenDeleteModal(false);
  };

  const confirmDelete = () => {
    if (serviceToDelete) {
      handleDeleteService(serviceToDelete.id);
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <InfoAdmin
        text="Serwis komputerowy"
        imagePath="/images/AdminPage/QuotationPage/image2.jpg"
        imageWidth={isXs || isSm ? "90%" : 600}
      />
      <Paper elevation={3} sx={{ p: 2 }}>
        {services.map((service, serviceIndex) => (
          <Box key={service.id} sx={{ mb: 2 }}>
            <Divider sx={{ my: 2 }} />
            <Typography
              variant="h6"
              sx={{
                paddingBottom: "12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                value={service.name}
                onChange={(e) =>
                  handleServiceChange(service.id, "name", e.target.value)
                }
                fullWidth
              />
            </Typography>

            <Grid item xs={12} sm={12} sx={{ paddingBottom: 2 }}>
              <TextField
                fullWidth
                label="Zakres cen"
                variant="outlined"
                size="small"
                value={service.priceRange}
                onChange={(e) =>
                  handleServiceChange(service.id, "priceRange", e.target.value)
                }
              />
            </Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                sx={{
                  height: "min-content",
                  alignSelf: "center",
                  color: changes[service.id] ? "#227722" : "default",
                }}
                onClick={() =>
                  handleUpdateService(service.id, {
                    name: service.name,
                    priceRange: service.priceRange,
                  })
                }
                disabled={!changes[service.id]}
              >
                <SaveIcon sx={{ fontSize: 30 }} />
              </IconButton>
              <IconButton
                sx={{
                  color: "#ad1f1f",
                  height: "min-content",
                  alignSelf: "center",
                }}
                onClick={() => openDeleteConfirmation(service)}
              >
                <DeleteForeverIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </div>
          </Box>
        ))}
        <Box sx={{ mt: 2 }}>
          <Divider sx={{ my: 5 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Nazwa usługi"
                variant="outlined"
                value={newService.name}
                size="small"
                onChange={(e) =>
                  setNewService({ ...newService, name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Zakres cen"
                size="small"
                variant="outlined"
                value={newService.priceRange}
                onChange={(e) =>
                  setNewService({ ...newService, priceRange: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNewService}
                fullWidth
                sx={{
                  my: 2,
                  color: "#fff",
                  background: "linear-gradient(180deg, #121212, #6d6d6de6 )",
                  fontWeight: 800,
                }}
              >
                Dodaj usługę
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <ConfirmDelete
        open={openDeleteModal}
        onClose={closeDeleteConfirmation}
        onConfirm={confirmDelete}
        title={
          serviceToDelete
            ? `Czy na pewno chcesz usunąć usługę "${serviceToDelete.name}"?`
            : ""
        }
      />
    </Box>
  );
};

export default ComputerServiceSection;
