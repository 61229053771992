import { Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const calculatePrice = (
  selectedService,
  pageCount,
  languageCount,
  isMobile,
  forInvalid,
  isXs,
  isSm,
  setError,
  setCalculatedPrice,
  setPriceDetails
) => {
  if (selectedService) {
    let pricePerPage = parseInt(selectedService.priceRange, 10);
    let pageCountCost = pricePerPage * pageCount;
    let languageCost =
      parseInt(selectedService.pricePerLanguage, 10) * languageCount;
    let totalCost = pageCountCost + languageCost;

    let detailsElements = [
      <Typography
        sx={{ display: "flex", fontSize: isXs || isSm ? "small" : "normal" }}
      >
        <KeyboardArrowRightIcon />
        Cena za stronę: {pricePerPage} zł x {pageCount} = {pageCountCost} zł
      </Typography>,
      <Typography
        sx={{ display: "flex", fontSize: isXs || isSm ? "small" : "normal" }}
      >
        <KeyboardArrowRightIcon />
        Cena za język: {selectedService.pricePerLanguage} zł x {languageCount} ={" "}
        {languageCost} zł
      </Typography>,
    ];

    if (isMobile) {
      let mobileCost = parseInt(selectedService.isMobile, 10);
      totalCost += mobileCost;
      detailsElements.push(
        <Typography
          sx={{
            display: "flex",
            fontSize: isXs || isSm ? "small" : "normal",
          }}
        >
          <KeyboardArrowRightIcon />
          Wersja mobilna: {mobileCost} zł
        </Typography>
      );
    }
    if (forInvalid) {
      let invalidCost = parseInt(selectedService.forInvaid, 10);
      totalCost += invalidCost;
      detailsElements.push(
        <Typography
          sx={{
            display: "flex",
            fontSize: isXs || isSm ? "small" : "normal",
          }}
        >
          <KeyboardArrowRightIcon />
          Dostosowanie dla niepełnosprawnych: {invalidCost} zł
        </Typography>
      );
    }

    if (totalCost === 0 || pageCount === 0) {
      setError(true);
      setCalculatedPrice("");
      setPriceDetails("");
    } else {
      setError(false);
      setCalculatedPrice(totalCost);
      setPriceDetails(detailsElements);
    }
  }
};
