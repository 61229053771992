import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useIsMobile from "./useIsMobile";
import { useLocation, Link as RouterLink } from "react-router-dom"; // Import useLocation and RouterLink

const useStyles = makeStyles({
  button: {
    margin: "0 10px !important",
    color: "#fff !important",
    height: "min-content",
    alignSelf: "center",
    transition: "transform 0.3s ease-in-out !important",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#000 !important",
      transform: "scale(1.1)",
    },
    "&.active": {
      backgroundColor: "#fff !important",
      color: "#000 !important",
      transform: "scale(1.1)",
    },
  },
  buttonMobile: {
    color: "#fff !important",
    padding: "20px 22px 20px 45px !important",
    width: "100%",
    justifyContent: "flex-start !important",
    "&:hover": {
      color: "#fff !important",
      borderRadius: 0,
      backgroundColor: "#000000c4 !important",
    },
    "&.active": {
      color: "#fff !important",
      borderRadius: 0,
      backgroundColor: "#000000c4",
    },
  },
  label: {
    fontWeight: 600,
    fontSize: 17,
    fontFamily: "inherit",
    marginTop: "2px",
  },
  labelMobile: {
    fontWeight: 600,
    fontSize: 15,
    fontFamily: "inherit",
    marginTop: "2px",
  },
});

const HeaderButton = ({ icon, label, style, to, ...props }) => {
  const { isXs, isSm } = useIsMobile();
  const location = useLocation();

  const classes = useStyles();
  const buttonClass = `${
    isXs || isSm ? classes.buttonMobile : classes.button
  } ${location.pathname === to ? "active" : ""}`;

  return (
    <Button
      component={RouterLink}
      to={to}
      className={buttonClass}
      startIcon={icon}
      style={style}
      {...props}
    >
      <span className={isXs || isSm ? classes.labelMobile : classes.label}>
        {label}
      </span>
    </Button>
  );
};

export default HeaderButton;
