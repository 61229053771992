import { makeStyles } from "@mui/styles";

const KnowledgePageStyles = makeStyles({
  body: {
    fontSize: 50,
    height: "100vh",
  },
});

export default KnowledgePageStyles;
