import React, { useState } from "react";
import {
  Paper,
  Button,
  TextField,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HttpsIcon from "@mui/icons-material/Https";
import axiosAuth from "../../../../api/axiosConfig";
import { endpoint } from "../../../../endpoint";
import InfoAdmin from "../../../../Assets/InfoAdmin";

const CredentialsPanel = ({
  userName,
  isXs,
  isSm,
  setSnackbarMessage,
  setSnackbarOpen,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [newUsername, setNewUsername] = useState(userName);
  const [newPassword, setNewPassword] = useState("");
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
    setShowPasswordForm(true);
  };

  const handlePasswordVerification = async () => {
    try {
      const response = await axiosAuth.post(
        `${endpoint.baseUrl}/auth/verifyPassword`,
        { password: passwordInput }
      );
      if (response.data.isValid) {
        setShowPasswordForm(false);

        setSnackbarMessage({
          text: "Hasło poprawne",
          severity: "success",
        });
      } else {
        setSnackbarMessage({
          text: "Niepoprawne hasło",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Verification failed:", error.response?.data);

      setSnackbarMessage({
        text: "Niepoprawne hasło",
        severity: "error",
      });
    }
    setSnackbarOpen(true);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axiosAuth.put(
        `${endpoint.baseUrl}/auth/update/user`,
        { newUsername, newPassword }
      );
      if (response.data.success) {
        setSnackbarMessage({
          text: "Dane zostały zaktualizowane",
          severity: "success",
        });
        window.location.reload();
      } else {
        setSnackbarMessage({
          text: "Nie udało się zaktualizować danych.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Failed to update user data", error.response?.data);

      setSnackbarMessage({
        text: "Nie udało się zaktualizować danych.",
        severity: "error",
      });
    }
    setSnackbarOpen(true);
  };

  const handleKeyPress = (e, action) => {
    if (e.key === "Enter") {
      action();
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <InfoAdmin text="Dane logowania" />
      <Paper
        sx={{ padding: 4, marginTop: 2, width: isXs || isSm ? "100%" : 600 }}
      >
        <Button
          onClick={handleEditClick}
          sx={{ color: "#000", minWidth: "min-content", float: "right" }}
        >
          <ModeEditIcon sx={{ fontSize: 30 }} />
        </Button>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Nazwa:</div>
          <div>{userName}</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Hasło:</div>
          <div style={{ display: "flex" }}>
            <HttpsIcon style={{ fontSize: 20 }} />
          </div>
        </div>
        {isEditing && showPasswordForm && (
          <Grid my={2} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <TextField
              label="Aktualne hasło"
              variant="outlined"
              type={passwordVisible ? "text" : "password"}
              size="small"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, handlePasswordVerification)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={handlePasswordVerification}
              sx={{ color: "#000", minWidth: "min-content" }}
            >
              <SendIcon sx={{ fontSize: 30 }} />
            </Button>
          </Grid>
        )}
        {isEditing && !showPasswordForm && (
          <Grid my={2} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <TextField
              label="Nazwa"
              variant="outlined"
              value={newUsername}
              size="small"
              onChange={(e) => setNewUsername(e.target.value)}
            />
            <TextField
              label="Nowe hasło"
              variant="outlined"
              type={passwordVisible ? "text" : "password"}
              size="small"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, handleSaveChanges)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={handleSaveChanges}
              sx={{ color: "#000", minWidth: "min-content" }}
            >
              <SaveIcon sx={{ fontSize: 30 }} />
            </Button>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default CredentialsPanel;
