import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSpring, animated } from "@react-spring/web"; // Zmiana importu
import useIsMobile from "../../Assets/useIsMobile";
import GeneralPageAdmin from "./Tabs/GeneralPageAdmin/GeneralPageAdmin";
import HomePageAdmin from "./Tabs/HomePageAdmin/HomePageAdmin";
import QuotationPageAdmin from "./Tabs/QuotationPageAdmin/QuotationPageAdmin";
import OfferPageAdmin from "./Tabs/OfferPageAdmin/OfferPageAdmin";
import AboutPageAdmin from "./Tabs/AboutPageAdmin/AboutPageAdmin";
import ContactPageAdmin from "./Tabs/ContactPageAdmin/ContactPageAdmin";
import FilePageAdmin from "./Tabs/FilePageAdmin/FilePageAdmin";

const useStyles = makeStyles({
  body: {
    fontSize: 20,
    padding: "80px 150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "75vh",
  },

  customTab: {
    fontSize: "15px !important",
    color: "#fff !important",
    fontWeight: "600 !important",
    fontStyle: "inherit !important",
    transition: "transform 0.3s ease-in-out !important",
    "&:hover": {
      transform: "translateY(-8px)",
      fontSize: "15px !important",
    },
    "&.Mui-selected": {
      transform: "translateY(-8px)",
      color: "#fff !important",
      fontWeight: "bold !important",
    },
  },
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AdminPanelPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  const { isXs, isSm } = useIsMobile();

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab !== null) {
      setSelectedTab(parseInt(savedTab, 10));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedTab", newValue);
  };

  const slideFromTop = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-100%)" },
    config: { tension: 130, friction: 30 },
    delay: 400,
  });

  const slideFromBottom = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(50%)" },
    config: { tension: 130, friction: 30 },
    delay: 400,
  });

  return (
    <Container sx={{ padding: isSm || isXs ? 0 : "auto" }}>
      <Box sx={{ width: "100%" }}>
        <animated.div style={slideFromTop}>
          <Typography
            variant="h3"
            gutterBottom
            align="center"
            p={5}
            color="#fff"
          >
            Panel administratora
          </Typography>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant={isXs || isSm ? "scrollable" : "standard"}
            centered
            TabIndicatorProps={{
              sx: { backgroundColor: "#fff !important" },
            }}
            sx={{
              "& .MuiTabs-scrollButtons": {
                color: "#fff",
              },
            }}
          >
            <Tab label="Ogólne" className={classes.customTab} />
            <Tab label="Strona główna" className={classes.customTab} />
            <Tab label="Oferta" className={classes.customTab} />
            <Tab label="Wycena" className={classes.customTab} />
            <Tab label="O nas" className={classes.customTab} />
            <Tab label="Kontakt" className={classes.customTab} />
            <Tab label="Pliki" className={classes.customTab} />
            <Tab
              label="Baza wiedzy"
              className={classes.customTab}
              sx={{ display: "none" }}
            />
          </Tabs>
        </animated.div>
        <animated.div style={slideFromBottom}>
          <TabPanel value={selectedTab} index={0}>
            <GeneralPageAdmin />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <HomePageAdmin />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <OfferPageAdmin />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <QuotationPageAdmin />
          </TabPanel>
          <TabPanel value={selectedTab} index={4}>
            <AboutPageAdmin />
          </TabPanel>
          <TabPanel value={selectedTab} index={5}>
            <ContactPageAdmin />
          </TabPanel>
          <TabPanel value={selectedTab} index={6}>
            <FilePageAdmin />
          </TabPanel>
        </animated.div>
      </Box>
    </Container>
  );
};

export default AdminPanelPage;
