import React, { useEffect, useState } from "react";
import { Typography, Button, Modal, Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { endpoint } from "../endpoint";
import PrivacyPolicyContent from "../PrivacyPolicyContent";

const useStyles = makeStyles({
  footer: {
    color: "#fff",
    width: "100%",
    position: "absolute",
    bottom: 0,
    right: 10,
    textAlign: "right",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 10px",
  },
  boxContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    border: "2px solid #000",
    boxShadow: "0px 0px 5px 2px rgb(245 245 245 / 15%)",
    background: "linear-gradient(180deg, #178f87, #050b13)",
    borderRadius: 28,
    padding: 20,
    outline: 0,
    animation: "$modalFadeIn 0.5s ease-in-out",
  },
  "@keyframes modalFadeIn": {
    "0%": {
      opacity: 0,
      transform: "translate(-50%, -45%)",
    },
    "100%": {
      opacity: 1,
      transform: "translate(-50%, -50%)",
    },
  },
  "@keyframes modalFadeOut": {
    "0%": {
      opacity: 1,
      transform: "translate(-50%, -50%)",
    },
    "100%": {
      opacity: 0,
      transform: "translate(-50%, -45%)",
    },
  },
  boxContentFadeOut: {
    animation: "$modalFadeOut 0.5s ease-in-out",
  },
  closeButton: {
    position: "absolute !important",
    top: 10,
    right: 10,
    color: "#fff !important",
  },
});

const Footer = () => {
  const classes = useStyles();
  const [version, setVersion] = useState("");
  const [open, setOpen] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    axios
      .get(`${endpoint.baseUrl}/auth/api/version`)
      .then((response) => {
        setVersion(response.data.version);
      })
      .catch((error) => {
        console.error("Error fetching version:", error);
      });
  }, []);

  const handleOpen = () => {
    setFadeOut(false);
    setOpen(true);
  };

  const handleClose = () => {
    setFadeOut(true);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  return (
    <footer className={classes.footer}>
      <Button
        onClick={handleOpen}
        style={{
          fontSize: 12,
          fontFamily: "math",
          color: "#e1e1e199",
          marginLeft: 30,
          textTransform: "none",
        }}
      >
        Polityka Prywatności
      </Button>
      <Typography
        sx={{
          fontSize: 12,
          fontFamily: "math",
          color: "#e1e1e199",
          alignSelf: "center",
        }}
      >
        {version
          ? `v${version} @2024 - Digital Hestech`
          : `@2024 - Digital Hestech`}
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
      >
        <Box
          className={`${classes.boxContent} ${
            fadeOut ? classes.boxContentFadeOut : ""
          }`}
        >
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <PrivacyPolicyContent />
        </Box>
      </Modal>
    </footer>
  );
};

export default Footer;
