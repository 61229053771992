import { useMediaQuery } from "@mui/material";

const useIsMobile = () => {
  const isXs = useMediaQuery("(max-width:600px)");
  const isSm = useMediaQuery("(min-width:601px) and (max-width:1080px)");
  const isMd = useMediaQuery("(min-width:1081px) and (max-width:1340px)");

  return { isXs, isSm, isMd };
};

export default useIsMobile;
