import { useState, useEffect } from "react";
import { endpoint } from "../endpoint";
import axiosAuth from "./axiosConfig";

export const useAdminCheck = () => {
  const [isAdmin, setIsAdmin] = useState(null);
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await axiosAuth.post(
          `${endpoint.baseUrl}/auth/checkAdmin`
        );
        setIsAdmin(response.data.isAdmin);
        setUserName(response.data.username);
      } catch (error) {
        console.log("Access denied:", error);
        setIsAdmin(false);
        setUserName(null);
      }
    };

    checkAdmin();
  }, []);

  return { isAdmin, userName };
};
