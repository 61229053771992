import React, { useState } from "react";
import {
  Box,
  IconButton,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import axiosAuth from "../../../../api/axiosConfig";
import { endpoint } from "../../../../endpoint";
import ConfirmDelete from "../components/ConfirmDelete";

const GallerySection = ({
  classes,
  images,
  setImages,
  handleOpen,
  fetchImages,
  setSnackbar,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isOrderChanged, setIsOrderChanged] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      const fileExtension = file.name.match(/\.[^/.]+$/)[0];

      const existingFileNames = images.map((image) => {
        const nameWithPath = image.original.split("/").pop();
        return nameWithPath.replace(/\.[^/.]+$/, "");
      });

      let newFileName = fileNameWithoutExtension;
      let counter = 1;

      while (existingFileNames.includes(newFileName)) {
        newFileName = `${fileNameWithoutExtension} (${counter})`;
        counter++;
      }

      const formData = new FormData();
      formData.append(
        "images",
        new File([file], `${newFileName}${fileExtension}`)
      );

      try {
        const response = await axiosAuth.post(
          `${endpoint.baseUrl}/homePageImages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setImages([
          ...images,
          {
            original: `${endpoint.baseUrl}/${response.data.imagePath}`,
            id: response.data.id,
          },
        ]);
        fetchImages();
        setSnackbar({
          open: true,
          message: "Zdjęcie zostało dodane.",
          severity: "success",
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Błąd podczas dodawania zdjęcia.",
          severity: "error",
        });
        console.error("Error uploading image", error);
      }
    }
  };

  const handleDeleteClick = (index) => {
    setSelectedImageIndex(index);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const imageId = images[selectedImageIndex].id;
    try {
      await axiosAuth.delete(`${endpoint.baseUrl}/homePageImages/${imageId}`);
      setImages(images.filter((_, i) => i !== selectedImageIndex));
      setSnackbar({
        open: true,
        message: "Zdjęcie zostało usunięte.",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Błąd podczas usuwania zdjęcia.",
        severity: "error",
      });
      console.error("Error deleting image", error);
    } finally {
      setDeleteDialogOpen(false);
      setSelectedImageIndex(null);
    }
  };

  const moveImage = (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= images.length) return;
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(index, 1);
    updatedImages.splice(newIndex, 0, movedImage);
    setImages(updatedImages);
    setIsOrderChanged(true);
  };

  const handleSaveOrder = async () => {
    try {
      await axiosAuth.put(`${endpoint.baseUrl}/homePageImages/order`, {
        images,
      });
      setIsOrderChanged(false);
      setSnackbar({
        open: true,
        message: "Kolejność zdjęć została zapisana.",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Błąd podczas zapisywania kolejności zdjęć.",
        severity: "error",
      });
      console.error("Error saving image order", error);
    }
  };

  return (
    <>
      <InfoAdmin
        text="Galeria zdjęć"
        imagePath="/images/AdminPage/HomePage/image1.jpg"
        imageWidth={classes.isXs || classes.isSm ? "90%" : 800}
      />
      <Box className={classes.imageRow}>
        <label htmlFor="upload-photo" className={classes.uploadButton}>
          <IconButton
            color="primary"
            component="span"
            sx={{
              border: "1px #fff solid",
              borderRadius: "4px",
              width: 180,
              height: 180,
            }}
          >
            <AddPhotoAlternateIcon sx={{ fontSize: 70, color: "#fff" }} />
          </IconButton>
          <input
            accept="image/*"
            className={classes.inputFile}
            id="upload-photo"
            type="file"
            onChange={handleFileChange}
          />
        </label>
        {images.map((image, index) => (
          <Card className={classes.imageCard} key={index}>
            <CardActionArea onClick={() => handleOpen(index)}>
              <CardMedia
                className={classes.media}
                image={image.original}
                title="Obraz strony głównej"
                component="img"
              />
            </CardActionArea>
            <CardActions
              disableSpacing
              style={{ position: "absolute", right: 0, top: 0 }}
            >
              <IconButton
                onClick={() => handleDeleteClick(index)}
                sx={{
                  backgroundColor: "#fff !important",
                  color: "#000",
                  padding: "2px",
                  transition: "transform 0.3s ease-in-out !important",
                  "&:hover": {
                    backgroundColor: "#fff !important",
                    color: "#000 !important",
                    transform: "scale(0.9)",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </CardActions>
            <CardActions
              disableSpacing
              style={{
                position: "absolute",
                bottom: 0,
                display: "flex",
                gap: 20,
              }}
            >
              <IconButton
                onClick={() => moveImage(index, -1)}
                sx={{
                  backgroundColor: "#fff !important",
                  color: "#000",
                  padding: "2px",
                  transition: "transform 0.3s ease-in-out !important",
                  "&:hover": {
                    backgroundColor: "#fff !important",
                    color: "#000 !important",
                    transform: "scale(0.9)",
                  },
                }}
              >
                <ArrowBackIosNewIcon sx={{ fontSize: 40 }} />
              </IconButton>
              <IconButton
                onClick={() => moveImage(index, 1)}
                sx={{
                  color: "#000",
                  backgroundColor: "#fff !important",
                  padding: "2px",
                  transition: "transform 0.3s ease-in-out !important",
                  "&:hover": {
                    backgroundColor: "#fff !important",
                    color: "#000 !important",
                    transform: "scale(0.9)",
                  },
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Box>
      <IconButton
        color="primary"
        onClick={handleSaveOrder}
        variant="contained"
        sx={{
          color: "#227722",
          display: "flex",
          float: "right",
          backgroundColor: "#fff",
          marginTop: 1,
        }}
        disabled={!isOrderChanged}
      >
        <SaveIcon />
      </IconButton>
      <ConfirmDelete
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Czy na pewno chcesz usunąć to zdjęcie?"
      />
    </>
  );
};

export default GallerySection;
