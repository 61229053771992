import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import HeaderButton from "../Assets/HeaderButton";
import HomeIcon from "@mui/icons-material/Home";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import InfoIcon from "@mui/icons-material/Info";
import CallIcon from "@mui/icons-material/Call";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import useIsMobile from "../Assets/useIsMobile";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { endpoint } from "../endpoint";
import axios from "axios";
import { useAdminCheck } from "../api/useAdminCheck";
import LogoutIcon from "@mui/icons-material/Logout";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useSpring, animated } from "@react-spring/web";

const useStyles = makeStyles({
  header: {
    color: "#fff",
    background: "linear-gradient(180deg, #24f4e570, #050b13)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 120,
    padding: "0 10px",
    boxShadow: "-1px 7px 18px 4px rgb(55 53 53 / 58%)",
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 1000,
    transition: "transform 0.3s ease",
  },
  button: {
    color: "#fff",
    margin: "0 10px",
  },
  companyTitle: {
    flexGrow: 1,
    textAlign: "left",
    fontSize: 30,
    fontWeight: 600,
    padding: "0 20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 2,
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    backgroundColor: "#333",
    color: "#fff",
  },
  menuButton: {
    marginRight: "0 !important",
  },
  drawer: {
    width: 250,
  },
  userAndMenuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
  },
  paperMobile: {
    "& .MuiPaper-root": {
      background: "linear-gradient(90deg, #050b13, #24f4e570)",
    },
  },
  placeholder: {
    height: 120,
  },
});
const Header = ({ setImageLoadingLogo }) => {
  const classes = useStyles();
  const { isMd, isXs, isSm } = useIsMobile();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [contactInfo, setContactInfo] = useState({ phone: null, email: null });
  const [logoClicks, setLogoClicks] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const { isAdmin, userName } = useAdminCheck();
  const [imageLoading, setImageLoading] = useState(true);

  const navigate = useNavigate();
  let clickTimeout = null;

  const [pageSettings, setPageSettings] = useState({
    offerPage: true,
    quotationPage: true,
    aboutPage: true,
    contactPage: true,
    knowledgePage: true,
  });

  const slideUp = useSpring({
    opacity: imageLoading ? 0 : drawerOpen ? 1 : 0,
    transform: imageLoading
      ? "translateY(-20%)"
      : drawerOpen
      ? "translateY(0px)"
      : "translateY(-20%)",
    from: { opacity: 0, transform: "translateY(-50%)" },
    config: { tension: 200, friction: 40 },
    delay: 130,
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await axios.get(`${endpoint.baseUrl}/contactPages`);
        setContactInfo({
          phone: response.data.phone,
          email: response.data.email,
        });
      } catch (error) {
        console.log("Error fetching contact info", error);
      }
    };

    const fetchPageSettings = async () => {
      try {
        const response = await axios.get(`${endpoint.baseUrl}/settingsPages`);
        setPageSettings(response.data);
      } catch (error) {
        console.log("Error fetching page settings", error);
      }

      try {
        const logoResponse = await axios.get(`${endpoint.baseUrl}/logoCompany`);
        if (logoResponse.data && logoResponse.data.imagePath) {
          setLogoUrl(`${endpoint.baseUrl}/${logoResponse.data.imagePath}`);
        } else {
          setLogoUrl("/logo.png");
        }
      } catch (error) {
        setLogoUrl("/logo.png");
      }
    };
    fetchContactInfo();
    fetchPageSettings();
  }, []);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY < 150) {
        setIsVisible(true);
      } else if (window.scrollY < lastScrollY) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogoClick = () => {
    clearTimeout(clickTimeout);
    setLogoClicks(logoClicks + 1);

    if (logoClicks === 0) {
      navigate("/homePage");
    } else if (logoClicks + 1 === 3) {
      navigate("/loginAdminPage");
      setLogoClicks(0);
    } else {
      clickTimeout = setTimeout(() => {
        setLogoClicks(0);
      }, 1000);
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("selectedTab");
    window.location.reload();
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const handleImageLoadLogo = () => {
    setImageLoadingLogo(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const renderButtons = () => (
    <>
      <HeaderButton icon={<HomeIcon />} label="Główna" to="/homePage" />
      {pageSettings.offerPage && (
        <HeaderButton
          icon={<BusinessCenterIcon />}
          label="Oferta"
          to="/offerPage"
        />
      )}
      {pageSettings.quotationPage && (
        <HeaderButton
          icon={<AttachMoneyIcon />}
          label="Wycena"
          to="/quotationPage"
        />
      )}
      {pageSettings.aboutPage && (
        <HeaderButton icon={<InfoIcon />} label="O Nas" to="/aboutPage" />
      )}
      {pageSettings.contactPage && (
        <HeaderButton icon={<CallIcon />} label="Kontakt" to="/contactPage" />
      )}
      {pageSettings.knowledgePage && (
        <HeaderButton
          icon={<MenuBookIcon />}
          label="Baza Wiedzy"
          to="/knowledgePage"
        />
      )}
      {pageSettings.filesPage && (
        <HeaderButton icon={<AttachFileIcon />} label="Pliki" to="/filesPage" />
      )}
      {isAdmin && (isXs || isSm) && (
        <HeaderButton icon={<LogoutIcon />} label="Wyloguj" onClick={logout} />
      )}
    </>
  );

  return (
    <>
      <header
        className={classes.header}
        style={{ transform: isVisible ? "translateY(0)" : "translateY(-100%)" }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              onClick={handleLogoClick}
              onLoad={handleImageLoadLogo}
              src={logoUrl}
              alt="logo"
              effect="blur"
              style={{
                height: isXs ? 90 : 100,
                opacity: 0.8,
                display: logoUrl ? "" : "none",
              }}
            />
          </div>
          {!isXs && !isSm && (
            <div className={classes.buttonContainer}>{renderButtons()}</div>
          )}
          <div className={classes.userAndMenuButton}>
            <Button
              disabled
              sx={{
                color: "transparent !important",
                minWidth: "min-content",
              }}
            >
              <AdminPanelSettingsIcon />
            </Button>

            {isAdmin && !isXs && !isSm && (
              <>
                <div
                  style={{
                    padding: "0 20px",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate("/adminPage");
                    }}
                    sx={{
                      gap: "6px",
                      alignItems: "end",
                      borderRadius: !isMd ? "15px" : "",
                      transition: !isMd ? "" : "transform 0.2s ease-in-out",
                      "&:hover": {
                        boxShadow: !isMd
                          ? "inset 0px 0px 15px 0px #857a7a80"
                          : "0px 0px 3px 2px #7d7d7d",
                        transform: !isMd ? "" : "scale(0.8)",
                      },
                    }}
                  >
                    <AdminPanelSettingsIcon fontSize="large" />
                    {!isMd && (
                      <Typography variant="h6">Witaj, {userName}</Typography>
                    )}
                  </IconButton>

                  <IconButton
                    color="inherit"
                    onClick={logout}
                    sx={{
                      transition: "transform 0.2s ease-in-out ",
                      "&:hover": {
                        transform: "scale(0.8)",
                        boxShadow: "0px 0px 3px 2px #7d7d7d",
                      },
                    }}
                  >
                    <LogoutIcon fontSize="large" />
                  </IconButton>
                </div>
              </>
            )}

            {(isXs || isSm) && (
              <>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {isAdmin && (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        navigate("/adminPage");
                      }}
                      sx={{
                        gap: "6px",
                        alignItems: "end",
                        borderRadius: "15px",
                        transition: "transform 0.2s ease-in-out ",

                        "&:hover": {
                          transform: "scale(0.9)",
                        },
                      }}
                    >
                      <AdminPanelSettingsIcon sx={{ fontSize: 30 }} />
                      <Typography>Witaj, {userName}</Typography>
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                  >
                    <MenuIcon
                      fontSize="large"
                      style={{ padding: "0px 20px" }}
                    />
                  </IconButton>
                </div>
              </>
            )}
          </div>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            className={classes.paperMobile}
          >
            <div
              className={classes.drawer}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <animated.div style={slideUp}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0",
                  }}
                >
                  <img
                    onClick={handleLogoClick}
                    onLoad={handleImageLoad}
                    src={logoUrl}
                    alt="logo"
                    effect="blur"
                    style={{
                      height: 120,
                      opacity: 0.8,
                      display: logoUrl ? "" : "none",
                    }}
                  />
                </div>
              </animated.div>
              <Divider style={{ margin: "0 10px ", backgroundColor: "#fff" }} />
              <div style={{ padding: "20px 0 " }}>{renderButtons()}</div>
              {contactInfo.email && contactInfo.phone && (
                <>
                  <Divider
                    style={{ margin: "0 10px ", backgroundColor: "#fff" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      padding: "20px 0",
                    }}
                  >
                    <IconButton
                      aria-label="email"
                      onClick={() =>
                        (window.location.href = `mailto:${contactInfo.email}`)
                      }
                    >
                      <AlternateEmailIcon
                        style={{ color: "#fff", fontSize: 25 }}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="phone"
                      onClick={() =>
                        (window.location.href = `tel:${contactInfo.phone}`)
                      }
                    >
                      <PhoneIcon style={{ color: "#fff", fontSize: 25 }} />
                    </IconButton>
                  </div>
                  <Divider
                    style={{ margin: "0 10px ", backgroundColor: "#fff" }}
                  />
                </>
              )}
            </div>
          </Drawer>
        </div>
      </header>
      <div className={classes.placeholder}></div>
    </>
  );
};

export default Header;
