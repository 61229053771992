import { makeStyles } from "@mui/styles";

const OfferPageStyles = makeStyles({
  body: {
    fontSize: 20,
    padding: "80px 150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "75vh",
  },
  bodyMobile: {
    fontSize: 20,
    padding: "80px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "75vh",
  },
  customTab: {
    fontSize: "20px !important",
    color: "#fff !important",
    fontWeight: "600 !important",
    fontStyle: "inherit !important",
    transition: "transform 0.3s ease-in-out !important",
    "&:hover": {
      transform: "translateY(-8px)",
    },
    "&.Mui-selected": {
      transform: "translateY(-8px)",
    },
  },
  tabIndicator: {
    backgroundColor: "#fff !important",
  },
  buttonTab: {
    width: "100%",
    backgroundColor: "transparent !important",
    color: "#c9c9c9 !important",
    border: "1px #c9c9c9 solid !important",
    borderRadius: "14px !important",
    minHeight: 65,
    fontWeight: "600 !important",
  },
  buttonTabActive: {
    background: "linear-gradient(180deg, #24f4e587, #050b13)",
    color: "#fff !important",
    border: "1px #fff solid !important",
  },
  firstSection: {
    fontWeight: "600 !important",
    fontSize: "22px !important",
    textAlign: "center",
    maxWidth: 900,
    paddingBottom: 40,
  },
  bottomSections: {
    paddingTop: 20,
  },
  otherSections: {
    display: "flex",
    gap: 10,
    color: "#c9c9c9",
  },
  imageGallery: {
    width: "100%",
    margin: "auto",
    "& .image-gallery-slide": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
    },
    "& .image-gallery-image": {
      borderRadius: 20,
    },
    "& .image-gallery-slides": {
      borderRadius: 20,
    },
    "& .image-gallery-slide img": {
      borderRadius: 20,
      maxHeight: "400px !important",
      maxWidth: "100%",
      width: "auto",
      display: "block",
    },
    "& .image-gallery-thumbnail-image": {
      maxHeight: 45,
      width: "auto",
    },
    "& .image-gallery-thumbnails-container": {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      overflowY: "auto",
      maxHeight: 120,
      "&::-webkit-scrollbar": {
        height: 5,
        width: 5,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: 4,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
    "& .image-gallery-thumbnails": {
      paddingTop: 30,
    },
    "& .image-gallery-thumbnails-wrapper": {},
    "& .image-gallery-content": {},
  },
  imageGalleryMobile: {
    width: "100%",
    margin: "auto",
    "& .image-gallery-slide": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
    },
    "& .image-gallery-slide img": {
      borderRadius: 10,
      maxHeight: "300px !important",
      maxWidth: "100%",
      width: "auto",
      display: "block",
    },

    "& .image-gallery-left-nav": {
      padding: 10,
    },
    "& .image-gallery-right-nav": {
      padding: 10,
    },

    "& .image-gallery-image": {
      borderRadius: 20,
    },
    "& .image-gallery-slides": {
      borderRadius: 20,
    },
    "& .image-gallery-thumbnail-image": {
      maxHeight: 35,
      width: "auto",
    },
    "& .image-gallery-thumbnails-container": {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      maxWidth: 550,
      overflowY: "auto",
      maxHeight: 100,
      "&::-webkit-scrollbar": {
        height: 5,
        width: 5,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: 10,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
    "& .image-gallery-thumbnails": {
      paddingTop: 30,
    },

    "& .image-gallery-thumbnails-wrapper": {},
    "& .image-gallery-content": {},
  },
});

export default OfferPageStyles;
