import React, { useEffect, useState } from "react";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  TextField,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Paper,
  FormControlLabel,
  Checkbox,
  Grid,
  Container,
} from "@mui/material";
import useIsMobile from "../../Assets/useIsMobile";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CalculateIcon from "@mui/icons-material/Calculate";
import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";
import { endpoint } from "../../endpoint";
import QuotationPageStyles from "./QuotationPageStyles";
import { InfoServis } from "./InfoServis";
import { calculatePrice } from "./CalculatePrice";
import { SpinnerDotted } from "spinners-react";
import { useSpring, animated, useTrail } from "@react-spring/web";

const QuotationPage = () => {
  const classes = QuotationPageStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const { isXs, isSm } = useIsMobile();
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [languageCount, setLanguageCount] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const [forInvalid, setForInvalid] = useState(false);
  const [priceDetails, setPriceDetails] = useState("");
  const [error, setError] = useState(false);
  const [services, setServices] = useState([]);
  const [quotationServices, setQuotationServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          `${endpoint.baseUrl}/quotationCalculate`
        );
        setServices(response.data);
      } catch (error) {
        console.error(
          "There was an error fetching the quotation calculate data:",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    const fetchQuotationServices = async () => {
      try {
        const response = await axios.get(`${endpoint.baseUrl}/quotationList`);
        setQuotationServices(response.data);
      } catch (error) {
        console.error(
          "There was an error fetching the quotation list data:",
          error
        );
      } finally {
        setLoading(false);
      }
    };
    fetchServices();
    fetchQuotationServices();
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    resetCalculator();
  };

  const selectService = (id) => {
    const service = services.find((s) => s.id === id);
    if (service) {
      setSelectedService(service);
      setPageCount(1);
      setLanguageCount(1);
      setIsMobile(false);
      setForInvalid(false);
      setCalculatedPrice("");
      setPriceDetails("");
    }
  };

  const handleCalculatePrice = () => {
    calculatePrice(
      selectedService,
      pageCount,
      languageCount,
      isMobile,
      forInvalid,
      isXs,
      isSm,
      setError,
      setCalculatedPrice,
      setPriceDetails
    );
  };

  const resetCalculator = () => {
    setSelectedService(null);
  };

  const renderServiceTable = (quotationServices) => (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: "transparent",
        boxShadow: "0px 0px 3px 1px rgb(245 245 245 / 18%)",
      }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: "#fff",
                borderBottom: "1px solid #5d5d5df5",
                fontSize: isXs ? 15 : 25,
                fontWeight: 600,
              }}
            >
              Nazwa usługi
            </TableCell>
            <TableCell
              align="right"
              sx={{
                textWrap: "nowrap",
                color: "#fff",
                borderBottom: "1px solid #5d5d5df5",
                fontSize: isXs ? 15 : 25,
                fontWeight: 600,
              }}
            >
              Zakres cen
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotationServices.map((service) => (
            <TableRow key={service.id}>
              <TableCell
                component="th"
                scope="row"
                sx={{ color: "#fff", borderBottom: "1px solid #5d5d5df5" }}
              >
                {service.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  whiteSpace: "nowrap",
                  minWidth: 80,
                  color: "#fff",
                  borderBottom: "1px solid #5d5d5df5",
                }}
              >
                {service.priceRange}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const nameTabs = {
    tabs: [{ label: "Aplikacje Webowe" }, { label: "Serwis komputerowy" }],
  };

  const slideFromRight = useSpring({
    opacity: loading ? 0 : 1,
    transform: loading ? "translateX(100%)" : "translateX(0px)",
    from: { opacity: 0, transform: "translateX(100%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const trail = useTrail(services.length, {
    opacity: loading ? 0 : 1,
    transform: loading ? "translateY(100px)" : "translateY(0px)",
    from: { opacity: 0, transform: "translateY(100px)" },
    config: { tension: 200, friction: 25 },
    delay: 200,
  });

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <SpinnerDotted size={90} color="#a9ffff" />
        </div>
      )}
      <Container
        style={{ display: loading ? "none" : "" }}
        className={isXs || isSm ? classes.bodyMobile : classes.body}
      >
        <animated.div style={slideFromRight}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            TabIndicatorProps={{ className: classes.tabIndicator }}
            style={{ marginBottom: 60 }}
          >
            {nameTabs.tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                className={
                  isXs || isSm ? classes.customTabMobile : classes.customTab
                }
              />
            ))}
          </Tabs>
        </animated.div>

        <TabPanel value={selectedTab} index={0}>
          {!selectedService ? (
            <Grid container direction="column" spacing={2} alignItems="center">
              {trail.map((style, index) => (
                <Grid item key={index} className={classes.buttonService}>
                  <animated.div style={style}>
                    <Button
                      variant="contained"
                      onClick={() => selectService(services[index].id)}
                      sx={{
                        minWidth: isXs ? 300 : 350,
                        minHeight: 60,
                        background:
                          "linear-gradient(180deg, #24f4e570, #050b13)",
                        transition: "transform 0.3s ease-in-out ",
                        boxShadow: "0px 0px 3px 1px rgb(245 245 245 / 18%)",
                        fontSize: isXs ? 15 : 20,
                        fontWeight: 800,
                        "&:hover": {
                          transform: "scale(0.9)",
                          background:
                            "linear-gradient(180deg, #24f4e570, #050b13)",
                          fontWeight: 800,
                          boxShadow: "0px 0px 3px 1px rgb(245 245 245 / 18%)",
                        },
                      }}
                    >
                      {services[index].name}
                    </Button>
                  </animated.div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              <animated.div>
                <Paper
                  sx={{
                    padding: "30px 15px",
                    width: "fit-content",
                    minWidth: isSm || isXs ? "auto" : "427px",
                    background: "linear-gradient(180deg, #03090ed4, #3bfff02e)",
                    boxShadow: "0px 0px 3px 1px rgb(245 245 245 / 18%)",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    alignItems="center"
                    sx={{ marginLeft: 0, width: "100%" }}
                  >
                    <Button
                      onClick={resetCalculator}
                      sx={{
                        color: "#fff",
                        minWidth: 20,
                        alignSelf: "flex-start",
                        padding: 0,
                        transition: "transform 0.3s ease-in-out ",
                        "&:hover": {
                          transform: "scale(1.3)",
                        },
                      }}
                    >
                      <ArrowBackIosNewIcon />
                    </Button>
                    <Typography
                      variant="h5"
                      sx={{
                        margin: "20px 0",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: 50,
                        fontWeight: 700,
                        marginBottom: 10,
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      {selectedService.name}
                    </Typography>
                    <Grid container spacing={2} alignItems="center" fullWidth>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          textAlign: "center",
                          padding: "10px 15px 5px 30px !important",
                        }}
                      >
                        <TextField
                          label="Ilość stron"
                          type="number"
                          value={pageCount}
                          size="small"
                          onChange={(e) => setPageCount(e.target.value)}
                          fullWidth
                          className={classes.inputWhite}
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          textAlign: "center",
                          padding:
                            isSm || isXs
                              ? "10px 15px 5px 30px !important"
                              : "10px 15px 5px 15px !important",
                        }}
                      >
                        <TextField
                          label="Ilość jęzków"
                          type="number"
                          value={languageCount}
                          size="small"
                          onChange={(e) => setLanguageCount(e.target.value)}
                          fullWidth
                          className={classes.inputWhite}
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          textAlign: isSm || isXs ? "" : "center",
                          paddingLeft: isSm || isXs ? 32 : "",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isMobile}
                              onChange={(e) => setIsMobile(e.target.checked)}
                              sx={{
                                color: "#fff",
                                "&.Mui-checked": {
                                  color: "#fff",
                                },
                              }}
                            />
                          }
                          label="Wersja mobilna"
                          className={classes.labelWhite}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          textAlign: isSm || isXs ? "" : "center",
                          paddingLeft: isSm || isXs ? 32 : "",
                        }}
                      >
                        <FormControlLabel
                          sx={{ width: "max-content" }}
                          control={
                            <Checkbox
                              checked={forInvalid}
                              onChange={(e) => setForInvalid(e.target.checked)}
                              sx={{
                                color: "#fff",
                                "&.Mui-checked": {
                                  color: "#fff",
                                },
                              }}
                            />
                          }
                          label="Dla niepełnosprawnych"
                          className={classes.labelWhite}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{ textAlign: "center", marginBottom: 4 }}
                      >
                        <Button onClick={handleCalculatePrice}>
                          <CalculateIcon
                            sx={{
                              color: "#fff",
                              fontSize: 45,
                              transition: "transform 0.3s ease-in-out ",
                              "&:hover": {
                                transform: "scale(1.3)",
                              },
                            }}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                    {error && (
                      <Typography
                        variant="h6"
                        color="error"
                        gutterBottom
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <ErrorIcon sx={{ marginRight: 1 }} />
                        Uzupełnij dane
                      </Typography>
                    )}
                    {calculatedPrice && (
                      <>
                        <InfoServis />
                        <Paper
                          sx={{
                            padding: isXs || isSm ? 1 : 3,
                            textAlign: "center",
                            backgroundColor: "transparent",
                            boxShadow: "0px 0px 3px 1px rgb(245 245 245 / 18%)",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              marginBottom: 2,
                              color: "#fff",
                            }}
                          >
                            Suma: {calculatedPrice} zł
                          </Typography>
                          <Grid
                            component="pre"
                            sx={{
                              display: "flex",
                              textAlign: "left",
                              flexDirection: "column",
                              color: "#fff",
                            }}
                          >
                            {priceDetails}
                          </Grid>
                        </Paper>
                      </>
                    )}
                  </Grid>
                </Paper>
              </animated.div>
            </>
          )}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <animated.div>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              {renderServiceTable(quotationServices)}
              <InfoServis />
            </Grid>
          </animated.div>
        </TabPanel>
      </Container>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default QuotationPage;
