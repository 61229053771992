import React, { useEffect, useState } from "react";
import { Container, Snackbar, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import useIsMobile from "../../../../Assets/useIsMobile";
import axios from "axios";
import { endpoint } from "../../../../endpoint";
import GallerySection from "./GallerySection";
import BoxSection from "./BoxSection";
import { SpinnerDotted } from "spinners-react";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const useStyles = makeStyles((theme) => ({
  imageRow: {
    display: "flex",
    overflowX: "auto",
    paddingBottom: 2,
  },
  imageCard: {
    flex: "0 0 auto",
    margin: 2,
    position: "relative",
    width: "auto !important",
    height: "180px !important",
  },
  media: {
    width: "auto !important",
    height: "180px !important",
  },
  inputFile: {
    display: "none",
  },
  uploadButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },
}));

const HomePageAdmin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [offers, setOffers] = useState([]);
  const { isXs, isSm } = useIsMobile();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const classes = useStyles();

  const handleOpen = (index) => {
    setTimeout(() => {
      setPhotoIndex(index);
      setIsOpen(true);
    }, 200);
  };

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${endpoint.baseUrl}/homePageImages`);
      const loadedImages = response.data.map((image) => ({
        original: `${endpoint.baseUrl}/${image.imagePath.replace(/\\/g, "/")}`,
        id: image.id,
      }));
      setImages(loadedImages);
    } catch (error) {
      console.error("Error fetching images", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBoxes = async () => {
    try {
      const response = await axios.get(`${endpoint.baseUrl}/homePageBox`);
      setOffers(response.data);
    } catch (error) {
      console.error("Failed to fetch box", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
    fetchBoxes();
  }, []);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <SpinnerDotted size={90} color="#a9ffff" />
      </div>
    );
  }

  return (
    <Container sx={{ padding: isSm || isXs ? 0 : "auto" }}>
      <GallerySection
        classes={classes}
        images={images}
        setImages={setImages}
        handleOpen={handleOpen}
        fetchImages={fetchImages}
        setSnackbar={setSnackbar}
      />
      <BoxSection
        offers={offers}
        setOffers={setOffers}
        fetchImages={fetchImages}
        setSnackbar={setSnackbar}
      />
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.map((img) => ({ src: img.original }))}
          currentIndex={photoIndex}
          on={{
            clickNext: () => setPhotoIndex((photoIndex + 1) % images.length),
            clickPrev: () =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length),
          }}
          plugins={[Zoom]}
        />
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default HomePageAdmin;
