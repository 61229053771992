import React, { useEffect, useState } from "react";
import { Tabs, Tab, Grid, Button } from "@mui/material";
import useIsMobile from "../../Assets/useIsMobile";
import axios from "axios";
import { endpoint } from "../../endpoint";
import OfferPageStyles from "./OfferPageStyles";
import { TabPanel } from "./TabPanel";
import { useSpring, animated } from "@react-spring/web";
import { useLocation } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";

const OfferPage = () => {
  const classes = OfferPageStyles();
  const [value, setValue] = useState(0);
  const { isXs, isSm } = useIsMobile();
  const [tabsData, setTabsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const [imageLoadingNext, setImageLoadingNext] = useState(true);
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);

  const fade = useSpring({
    opacity: isVisible && !imageLoadingNext ? 1 : 0,
    config: { tension: 50, friction: 10 },
    visibility: isVisible && !imageLoadingNext ? "visible" : "hidden",
  });

  const slideUp = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform: loading || imageLoading ? "translateY(-50%)" : "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-50%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const slideFromRight = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform: loading || imageLoading ? "translateX(100%)" : "translateX(0px)",
    from: { opacity: 0, transform: "translateX(100%)" },
    config: { tension: 130, friction: 30 },
    delay: 400,
  });

  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${endpoint.baseUrl}/offerPages`);
        setTabsData(response.data);
        const selectedTitle = location.state?.selectedTitle;
        const defaultIndex = response.data.findIndex(
          (tab) => tab.label === selectedTitle
        );
        setValue(defaultIndex !== -1 ? defaultIndex : 0);
        const allImageUrls = response.data.flatMap((tab) =>
          tab.images.map((image) => image.url)
        );
        preloadImages(allImageUrls);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [location.state?.selectedTitle]);

  const handleImageLoad = () => {
    setImageLoading(false);
    setImageLoadingNext(false);
  };

  const handleChange = (event, newValue) => {
    setIsVisible(false);
    setImageLoadingNext(true);
    const imageUrls = tabsData[newValue].images.map((image) => image.url);
    preloadImages(imageUrls);
    setValue(newValue);
    setImageLoadingNext(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 250);
  };

  const renderTabs = () => {
    if (isXs || isSm) {
      return (
        <Grid container spacing={2}>
          {tabsData.map((tab, index) => (
            <Grid item xs={6} key={index}>
              <animated.div>
                <Button
                  variant="outlined"
                  className={`${classes.buttonTab} ${
                    value === index ? classes.buttonTabActive : ""
                  }`}
                  onClick={() => handleChange(null, index)}
                >
                  {tab.label}
                </Button>
              </animated.div>
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return (
        <animated.div style={slideFromRight}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            TabIndicatorProps={{ className: classes.tabIndicator }}
            style={{ marginBottom: 60 }}
          >
            {tabsData.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                className={classes.customTab}
              />
            ))}
          </Tabs>
        </animated.div>
      );
    }
  };

  return (
    <>
      {(loading || imageLoading) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <SpinnerDotted size={90} color="#a9ffff" />
        </div>
      )}
      <animated.div
        style={{ ...slideUp, display: loading || imageLoading ? "none" : "" }}
        className={isXs || isSm ? classes.bodyMobile : classes.body}
      >
        {renderTabs()}
        <animated.div style={fade}>
          {tabsData.map((tab, index) => (
            <animated.div key={index}>
              <TabPanel
                value={value}
                index={index}
                classes={classes}
                tab={tab}
                onImageLoad={handleImageLoad}
              />
            </animated.div>
          ))}
        </animated.div>
      </animated.div>
    </>
  );
};

export default OfferPage;
