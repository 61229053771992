import React, { useState } from "react";
import {
  Paper,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Divider,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BackspaceIcon from "@mui/icons-material/Backspace";
import axiosAuth from "../../../../api/axiosConfig";
import { endpoint } from "../../../../endpoint";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import ConfirmDelete from "../components/ConfirmDelete";

const DescriptionSection = ({
  isXs,
  isSm,
  aboutData,
  setAboutData,
  setSnackbarMessage,
  setSnackbarOpen,
}) => {
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [modifiedSections, setModifiedSections] = useState([]);

  const handleSectionTextChange = (sectionIndex, textIndex, value) => {
    const newData = { ...aboutData };
    newData.sections[sectionIndex].content[textIndex] = value;
    setAboutData(newData);
    if (!modifiedSections.includes(sectionIndex)) {
      setModifiedSections([...modifiedSections, sectionIndex]);
    }
  };

  const handleAddSectionText = (sectionIndex) => {
    const newData = { ...aboutData };
    newData.sections[sectionIndex].content.push("");
    setAboutData(newData);
    if (!modifiedSections.includes(sectionIndex)) {
      setModifiedSections([...modifiedSections, sectionIndex]);
    }
  };

  const handleDeleteSectionText = (sectionIndex, textIndex) => {
    const newData = { ...aboutData };
    newData.sections[sectionIndex].content.splice(textIndex, 1);
    setAboutData(newData);
    if (!modifiedSections.includes(sectionIndex)) {
      setModifiedSections([...modifiedSections, sectionIndex]);
    }
  };

  const handleTitleChange = (sectionIndex, value) => {
    const newData = { ...aboutData };
    newData.sections[sectionIndex].title = value;
    setAboutData(newData);
    if (!modifiedSections.includes(sectionIndex)) {
      setModifiedSections([...modifiedSections, sectionIndex]);
    }
  };

  const handleDeleteSection = async () => {
    const sectionIndex = deleteIndex;
    const section = aboutData.sections[sectionIndex];
    try {
      if (section.id) {
        await axiosAuth.delete(`${endpoint.baseUrl}/aboutUs/${section.id}`);
      }

      const newData = { ...aboutData };
      newData.sections.splice(sectionIndex, 1);
      setAboutData(newData);

      setSnackbarMessage({
        text: "Sekcja została usunięta",
        severity: "success",
      });
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage({
        text: "Błąd podczas usuwania sekcji",
        severity: "error",
      });
      setSnackbarOpen(true);
    } finally {
      setConfirmOpen(false);
    }
  };

  const handleSave = async (sectionIndex) => {
    const section = aboutData.sections[sectionIndex];

    if (!section.title || section.content.some((text) => !text.trim())) {
      setSnackbarMessage({
        text: "Uzupełnij pola",
        severity: "warning",
      });
      setSnackbarOpen(true);
      return;
    }

    try {
      if (section && !section.isNew) {
        if (section.id) {
          await axiosAuth.put(
            `${endpoint.baseUrl}/aboutUs/${section.id}`,
            section
          );
          setSnackbarMessage({
            text: "Sekcja została zaktualizowana",
            severity: "success",
          });
        }
      } else if (section && section.isNew) {
        await axiosAuth.post(`${endpoint.baseUrl}/aboutUs`, section);
        delete section.isNew;
        setSnackbarMessage({
          text: "Nowa sekcja została dodana",
          severity: "success",
        });
      }

      const response = await axios.get(`${endpoint.baseUrl}/aboutUs`);
      setAboutData({
        ...aboutData,
        sections: [...response.data, { title: "", content: [""], isNew: true }],
      });
      setModifiedSections(
        modifiedSections.filter((index) => index !== sectionIndex)
      );
    } catch (error) {
      setSnackbarMessage({
        text: "Błąd podczas zapisywania sekcji",
        severity: "error",
      });
    } finally {
      setSnackbarOpen(true);
    }
  };

  return (
    <Paper
      sx={{
        my: 2,
        padding: isXs || isSm ? 2 : 4,
        width: isXs || isSm ? "100%" : 600,
        textAlign: "center",
      }}
    >
      {aboutData.sections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <Typography variant="h6" sx={{ mt: 3, textAlign: "center" }}>
            {section.isNew ? "Nowa sekcja" : `#${sectionIndex + 1}`}
          </Typography>
          <Grid container sx={{ flexWrap: "nowrap" }}>
            <TextField
              label="Tytuł sekcji"
              variant="outlined"
              fullWidth
              value={section.title || ""}
              onChange={(e) => handleTitleChange(sectionIndex, e.target.value)}
              sx={{ mb: 2 }}
              size="small"
            />
          </Grid>

          {section.content.map((text, textIndex) => (
            <TextField
              key={textIndex}
              label={`Tekst sekcji ${textIndex + 1}`}
              variant="outlined"
              fullWidth
              multiline
              value={text}
              onChange={(e) =>
                handleSectionTextChange(sectionIndex, textIndex, e.target.value)
              }
              sx={{ my: 2 }}
              size="small"
              InputProps={{
                endAdornment:
                  textIndex === section.content.length - 1 ? (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={section.content.length < 2}
                        onClick={() =>
                          handleDeleteSectionText(
                            sectionIndex,
                            section.content.length - 1
                          )
                        }
                        sx={{
                          color: "#ad1f1f",
                          height: "min-content",
                          float: "right",
                          padding: "5px",
                        }}
                      >
                        <BackspaceIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
            />
          ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <IconButton
                onClick={() => handleAddSectionText(sectionIndex)}
                color="primary"
                sx={{ float: "right" }}
              >
                <AddIcon sx={{ fontSize: 30, padding: 0 }} />
              </IconButton>
            </div>
            <div>
              {!section.isNew && (
                <>
                  <IconButton
                    onClick={() => handleSave(sectionIndex)}
                    variant="contained"
                    sx={{
                      color: "#227722",
                    }}
                    disabled={!modifiedSections.includes(sectionIndex)}
                  >
                    <SaveIcon sx={{ fontSize: 30, padding: 0 }} />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setDeleteIndex(sectionIndex);
                      setConfirmOpen(true);
                    }}
                    variant="contained"
                    sx={{
                      color: "#ad1f1f",
                    }}
                  >
                    <DeleteForeverIcon sx={{ fontSize: 30, padding: 0 }} />
                  </IconButton>
                </>
              )}
            </div>
          </div>
          {section.isNew && (
            <Button
              fullWidth
              onClick={() => handleSave(sectionIndex)}
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                fontWeight: 800,
                background: section.isNew
                  ? "linear-gradient(180deg, #121212,  #6d6d6de6 ) "
                  : "",
              }}
            >
              {"Dodaj nową sekcję"}
            </Button>
          )}
          <Divider sx={{ my: 2 }} />
        </React.Fragment>
      ))}

      <ConfirmDelete
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleDeleteSection}
        title={`Czy na pewno chcesz usunąć sekcję #${deleteIndex + 1}?`}
      />
    </Paper>
  );
};

export default DescriptionSection;
