import axios from "axios";
import { endpoint } from "../endpoint";

const axiosAuth = axios.create({
  baseURL: endpoint.baseUrl,
});

axiosAuth.interceptors.request.use(
  (config) => {
    if (config.requiresAuth !== false) {
      const token = localStorage.getItem("authToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosAuth;
