import React, { useEffect, useState } from "react";
import { Typography, Container } from "@mui/material";
import useIsMobile from "../../Assets/useIsMobile";
import axios from "axios";
import { endpoint } from "../../endpoint";
import AboutPageStyles from "./AboutPageStyles";
import { SpinnerDotted } from "spinners-react";
import { useSpring, animated } from "@react-spring/web";

const AboutPage = () => {
  const classes = AboutPageStyles();
  const { isXs, isSm } = useIsMobile();
  const [aboutData, setAboutData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const imageResponse = await axios.get(
          `${endpoint.baseUrl}/aboutUsImage`
        );
        const sectionsResponse = await axios.get(`${endpoint.baseUrl}/aboutUs`);
        if (imageResponse.data && imageResponse.data[0].imagePath) {
          setImageData(imageResponse.data[0]);
        }
        setAboutData(sectionsResponse.data);
      } catch (error) {
        console.error("There was an error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const processText = (text) => {
    return text
      .replace(/(\s)([a-zA-Z]{1,2})(\s)/g, "$1$2&nbsp;$3")
      .replace(/(\s)([a-zA-Z]{1,2})$/, "&nbsp;$2")
      .replace(/ ([a-zA-Z]{1,2}) /g, " $1&nbsp;");
  };

  const slideUp = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform: loading || imageLoading ? "translateY(-50%)" : "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-50%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const leftAnimation = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform:
      loading || imageLoading ? "translateX(-100%)" : "translateX(0px)",
    from: { opacity: 0, transform: "translateX(-100%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const rightAnimation = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform: loading || imageLoading ? "translateX(100%)" : "translateX(0px)",
    from: { opacity: 0, transform: "translateX(100%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const imageBottomAnimation = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform: loading || imageLoading ? "translateY(50%)" : "translateY(0px)",
    from: { opacity: 0, transform: "translateY(50%)" },
    config: { tension: 130, friction: 30 },
    delay: aboutData ? aboutData.length * 200 : 200,
  });

  return (
    <>
      {(loading || imageLoading) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <SpinnerDotted size={90} color="#a9ffff" />
        </div>
      )}
      <Container
        className={isXs || isSm ? classes.rootMobile : classes.root}
        style={{ display: loading || imageLoading ? "none" : "" }}
      >
        {imageData?.imagePath && (
          <animated.div style={{ ...slideUp, alignSelf: "center" }}>
            <img
              src={`${endpoint.baseUrl}/${imageData.imagePath.replace(
                /\\/g,
                "/"
              )}`}
              alt="logo"
              onLoad={() => setImageLoading(false)}
              style={{
                width: "300px",
                alignSelf: "center",
                paddingBottom: "60px",
                opacity: 0.8,
              }}
            />
          </animated.div>
        )}
        {aboutData &&
          aboutData.map((section, index) => {
            const sectionAnimation =
              index % 2 === 0 ? rightAnimation : leftAnimation;

            return (
              <animated.div
                key={index}
                className={classes.section}
                style={sectionAnimation}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ color: "#fff", textAlign: "left" }}
                >
                  {section.title}
                </Typography>
                {section.content.map((paragraph, pIndex) => (
                  <Typography
                    key={pIndex}
                    paragraph
                    className={classes.typography}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: processText(paragraph),
                      }}
                    />
                  </Typography>
                ))}
              </animated.div>
            );
          })}
        <animated.img
          src={"/images/AboutPage/image1.png"}
          alt="aboutUs"
          style={{
            width: "100%",
            height: "auto",
            opacity: 0.8,
            margin: "20px 0",
            filter: "invert(1)",
            ...imageBottomAnimation,
          }}
        />
      </Container>
    </>
  );
};

export default AboutPage;
