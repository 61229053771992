import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import axiosAuth from "../../../../api/axiosConfig";
import { endpoint } from "../../../../endpoint";
import ConfirmDelete from "../components/ConfirmDelete";

const BoxSection = ({ offers, setOffers, fetchImages, setSnackbar }) => {
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    id: null,
    index: null,
  });

  const handleConfirmDelete = (id, index) => {
    setConfirmDelete({ open: true, id, index });
  };

  const handleDeleteClose = () => {
    setConfirmDelete({ open: false, id: null, index: null });
  };

  const handleDeleteConfirm = async () => {
    const id = confirmDelete.id;
    await axiosAuth.delete(`${endpoint.baseUrl}/homePageBox/${id}`);
    setOffers(offers.filter((offer) => offer.id !== id));
    setSnackbar({
      open: true,
      message: "Box został usunięty.",
      severity: "success",
    });
    handleDeleteClose();
  };

  const addNewBox = async () => {
    const newBox = offers.find((offer) => offer.isNew);
    if (newBox && newBox.title && newBox.description) {
      try {
        const response = await axiosAuth.post(
          `${endpoint.baseUrl}/homePageBox`,
          {
            title: newBox.title,
            description: newBox.description,
          }
        );
        setOffers(
          offers.map((offer) =>
            offer.id === newBox.id ? { ...response.data, isNew: false } : offer
          )
        );
        fetchImages();
        setSnackbar({
          open: true,
          message: "Nowy box został dodany.",
          severity: "success",
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Błąd podczas dodawania boxa.",
          severity: "error",
        });
      }
    } else {
      setSnackbar({
        open: true,
        message: "Tytuł i opis nie mogą być puste.",
        severity: "warning",
      });
    }
  };

  const handleFieldChange = (id, field, value) => {
    setOffers(
      offers.map((offer) => {
        if (offer.id === id) {
          return { ...offer, [field]: value, isChanged: true };
        }
        return offer;
      })
    );
  };

  const saveChanges = async (id) => {
    const updatedOffer = offers.find((offer) => offer.id === id);
    if (updatedOffer && updatedOffer.title && updatedOffer.description) {
      try {
        await axiosAuth.put(
          `${endpoint.baseUrl}/homePageBox/${id}`,
          updatedOffer
        );
        setOffers(
          offers.map((offer) =>
            offer.id === id ? { ...offer, isChanged: false } : offer
          )
        );
        setSnackbar({
          open: true,
          message: "Box został zaktualizowany.",
          severity: "success",
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Błąd podczas zapisywania zmian.",
          severity: "error",
        });
      }
    } else {
      setSnackbar({
        open: true,
        message: "Tytuł i opis nie mogą być puste.",
        severity: "warning",
      });
    }
  };

  useEffect(() => {
    if (!offers.some((offer) => offer.isNew)) {
      const newBoxTemplate = {
        id: Date.now(),
        title: "",
        description: "",
        isNew: true,
      };
      setOffers([...offers, newBoxTemplate]);
    }
  }, [offers, setOffers]);

  return (
    <>
      <InfoAdmin
        text="Boxy na dole"
        imagePath="/images/AdminPage/HomePage/image2.jpg"
        imageWidth={"90%"}
      />
      <Grid justifyContent="center" display="flex">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
            width: "100%",
          }}
        >
          {offers.map((offer, index) => (
            <Box
              key={offer.id}
              sx={{
                marginBottom: 2,
                display: "flex !important",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" sx={{ alignSelf: "center" }}>
                {offer.isNew ? "Nowy box" : `#${index + 1}`}
              </Typography>
              <TextField
                label="Tytuł"
                variant="outlined"
                size="small"
                multiline
                value={offer.title}
                onChange={(e) =>
                  handleFieldChange(offer.id, "title", e.target.value)
                }
                sx={{ marginBottom: 1 }}
              />
              <TextField
                label="Opis"
                variant="outlined"
                multiline
                rows={4}
                size="small"
                value={offer.description}
                onChange={(e) =>
                  handleFieldChange(offer.id, "description", e.target.value)
                }
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                {!offer.isNew && (
                  <>
                    <IconButton
                      onClick={() => saveChanges(offer.id)}
                      style={{
                        color: offer.isChanged ? "#227722" : "#00000042",
                      }}
                      disabled={!offer.isChanged}
                    >
                      <SaveIcon sx={{ fontSize: 30, padding: 0 }} />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleConfirmDelete(offer.id, index + 1)}
                      sx={{ color: "#ad1f1f", marginLeft: "auto" }}
                    >
                      <DeleteForeverIcon sx={{ fontSize: 30, padding: 0 }} />
                    </IconButton>
                  </>
                )}
              </div>
              {!offer.isNew && <Divider sx={{ my: 2 }} />}
            </Box>
          ))}
          <Button
            variant="outlined"
            color="primary"
            onClick={addNewBox}
            sx={{
              my: 2,
              color: "#fff",
              background: "linear-gradient(180deg, #121212,  #6d6d6de6 )",
              fontWeight: 800,
            }}
          >
            Dodaj nowy box
          </Button>
        </Paper>
      </Grid>

      {confirmDelete.index !== null && (
        <ConfirmDelete
          open={confirmDelete.open}
          onClose={handleDeleteClose}
          onConfirm={handleDeleteConfirm}
          title="Potwierdź usunięcie"
          content={`Czy na pewno chcesz usunąć box #${confirmDelete.index}?`}
        />
      )}
    </>
  );
};

export default BoxSection;
