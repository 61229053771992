import { makeStyles } from "@mui/styles";

const HomePageStyles = makeStyles({
  body: {
    padding: "30px 130px",
  },
  bodyMobile: {
    padding: "10px 20px 80px 20px",
  },
  gallery: {
    width: "100%",
    margin: "auto",
    marginBottom: 20,

    "& .image-gallery-image": {
      borderRadius: 20,
    },
    "& .image-gallery-slides": {
      borderRadius: 20,
    },
    "& .image-gallery-slide img": {
      borderRadius: 20,
      maxHeight: "600px !important",
      maxWidth: "100%",
      width: "auto",
      display: "block",
    },
  },
  galleryMobile: {
    width: "100%",
    margin: "auto",
    padding: "60px 0",

    "& .image-gallery-image": {
      borderRadius: 20,
    },
    "& .image-gallery-slides": {
      borderRadius: 20,
    },
    "& .image-gallery-slide img": {
      borderRadius: 10,
      maxHeight: "600px !important",
      maxWidth: "100%",
      width: "auto",
      display: "block",
    },
    "& .image-gallery-left-nav": {
      padding: 10,
    },
    "& .image-gallery-right-nav": {
      padding: 10,
    },
  },

  box: {
    padding: 20,
    textAlign: "center",
  },
  boxTitle: {
    fontWeight: "600 !important",
  },
  boxDescription: {
    marginTop: "15px !important",
    fontSize: "12px !important",
  },
});

export default HomePageStyles;
