import { Typography } from "@mui/material";
import React from "react";
import KnowledgePageStyles from "./KnowledgePageStyles";

const KnowledgePage = () => {
  const classes = KnowledgePageStyles();

  return (
    <div className={classes.body}>
      <Typography
        color="#fff"
        sx={{
          fontSize: 50,
          fontWeight: 600,
          display: "flex",
          justifyContent: "center",
        }}
      >
        Baza wiedzy
      </Typography>
    </div>
  );
};

export default KnowledgePage;
