import { makeStyles } from "@mui/styles";

const ContactPageStyles = makeStyles({
  body: {
    fontSize: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 100,
  },
  form: {
    marginTop: 2,
  },
  submitButton: {
    marginTop: "20px !important",
    float: "right",
    color: "#fff",
    background: "linear-gradient(180deg, #24f4e570, #050b13)",
    boxShadow: "0px 0px 3px 1px rgb(245 245 245 / 18%) !important",
    backgroundColor: "transparent !important",
    fontWeight: "800 !important",
  },
  companyInfo: {
    marginTop: 4,
    padding: 20,
    display: "grid",
    gap: 5,
    backgroundColor: "transparent !important",
    background: "linear-gradient(180deg, #03090ede, #8a8a8a21)",
    boxShadow: "0px 0px 5px 2px rgb(245 245 245 / 15%) !important",
  },
  mapContainer: {
    marginTop: 4,
    overflow: "hidden",
    width: "100%",
    height: "400px",
    border: "none",
    paddingBottom: 100,
  },
  inputWhite: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
    "& .MuiInputBase-input": {
      color: "#fff ",
    },
    "& .MuiInputLabel-root": {
      color: "#fff !important",
    },
  },
});

export default ContactPageStyles;
