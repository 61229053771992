import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const InfoServis = () => {
  return (
    <Typography
      variant="h6"
      sx={{
        fontWeight: 600,
        marginBottom: 2,
        textAlign: "center",
        maxWidth: 350,
        color: "#b7aeae",
      }}
    >
      Pamiętaj, że jest to propozycja wyceny, aby dowiedzieć się więcej {""}
      <Link
        component={RouterLink}
        to="/contactPage"
        sx={{
          color: "#34fcebd9",
          textDecoration: "none",
          "&:hover": {
            color: "#fff",
          },
        }}
      >
        skontaktuj się z nami
      </Link>
    </Typography>
  );
};
