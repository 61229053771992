import React, { useEffect, useState } from "react";
import {
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import axiosAuth from "../../../../api/axiosConfig";
import { endpoint } from "../../../../endpoint";

const pageLabels = {
  offerPage: "Oferta",
  quotationPage: "Wycena",
  aboutPage: "O Nas",
  contactPage: "Kontakt",
  knowledgePage: "Baza Wiedzy",
  filesPage: "Pliki",
};

const SettingsPanel = ({ isXs, isSm }) => {
  const [pageViews, setPageViews] = useState({
    offerPage: true,
    quotationPage: true,
    aboutPage: true,
    contactPage: true,
    filesPage: true,
    knowledgePage: true,
  });
  const [changed, setChanged] = useState(false);
  const [pageViewsChanges, setPageViewsChanges] = useState({});

  const fetchSettings = async () => {
    try {
      const response = await axiosAuth.get(`${endpoint.baseUrl}/settingsPages`);
      const { id, createdAt, updatedAt, ...updatedSettings } = response.data;
      setPageViews(updatedSettings);
    } catch (error) {
      console.error("Failed to fetch settings", error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleSwitchChange = (setting) => {
    const newValue = !pageViews[setting];
    setPageViews({ ...pageViews, [setting]: newValue });
    setPageViewsChanges({ ...pageViewsChanges, [setting]: newValue });
    setChanged(true);
  };

  const handleSwitchChanges = async () => {
    try {
      const response = await axiosAuth.put(
        `${endpoint.baseUrl}/settingsPages`,
        pageViewsChanges
      );
      const { id, createdAt, updatedAt, ...updatedSettings } = response.data;
      setPageViews(updatedSettings);
      setChanged(false);
      setPageViewsChanges({});
      window.location.reload();
    } catch (error) {
      console.error("Failed to save settings", error);
    }
  };

  return (
    <>
      <InfoAdmin text="Wyświetlanie widoków" />
      <Paper
        sx={{
          padding: 4,
          width: isXs || isSm ? "100%" : 600,
          position: "relative",
        }}
      >
        <FormGroup
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            gap: isXs ? "7px" : 8,
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridTemplateRows: "repeat(2, auto)",
          }}
        >
          {Object.entries(pageViews).map(([page, isVisible]) => (
            <FormControlLabel
              key={page}
              sx={{
                flexDirection: "column",
                marginLeft: 0,
                marginRight: 0,
                "& .MuiFormControlLabel-label": {
                  fontWeight: 600,
                  fontSize: isXs ? 12 : "",
                  textWrap: "nowrap",
                },
              }}
              control={
                <Switch
                  size={isXs || isSm ? "small" : "medium"}
                  checked={isVisible}
                  onChange={() => handleSwitchChange(page)}
                  color="default"
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": { color: "#000" },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#000",
                    },
                  }}
                />
              }
              label={pageLabels[page]}
            />
          ))}
          <IconButton
            onClick={handleSwitchChanges}
            sx={{
              color: "#227722",
              minWidth: "min-content",
              position: "absolute",
              right: 5,
              top: 5,
            }}
            disabled={!changed}
          >
            <SaveIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </FormGroup>
      </Paper>
    </>
  );
};

export default SettingsPanel;
