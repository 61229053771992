import React, { useEffect, useState } from "react";
import { Container, Snackbar, Alert } from "@mui/material";
import useIsMobile from "../../../../Assets/useIsMobile";
import axios from "axios";
import { endpoint } from "../../../../endpoint";
import axiosAuth from "../../../../api/axiosConfig";
import { useAdminCheck } from "../../../../api/useAdminCheck";
import SettingsPanel from "./SettingsPanel";
import EmailsPanel from "./EmailPanel";
import LogoPanel from "./LogoPanel";
import CredentialsPanel from "./CredentialsPanel";
import { SpinnerDotted } from "spinners-react";

const GeneralPageAdmin = () => {
  const [emails, setEmails] = useState([]);
  const { userName } = useAdminCheck();
  const { isXs, isSm } = useIsMobile();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState({
    text: "",
    severity: "",
  });
  const [logoImage, setLogoImage] = useState(null);

  const fetchEmails = async () => {
    try {
      const response = await axios.get(`${endpoint.baseUrl}/emails`);
      setEmails(response.data);
    } catch (error) {
      console.error("Failed to fetch emails", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseImage = await axios.get(
          `${endpoint.baseUrl}/logoCompany`
        );
        if (responseImage.data && responseImage.data.imagePath) {
          setLogoImage(responseImage.data);
        } else {
          setLogoImage(null);
        }
      } catch (error) {
        console.error("There was an error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    fetchEmails();
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setLogoImage({ file: file, imagePath: imageUrl });

      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await axiosAuth.post(
          `${endpoint.baseUrl}/logoCompany`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setLogoImage({
          ...response.data,
          imagePath: `${response.data.imagePath.replace(/\\/g, "/")}`,
        });
        window.location.reload();
      } catch (error) {
        console.error("Błąd przy zapisywaniu obrazu", error);
      }
    }
  };

  const handleRemoveImage = async () => {
    if (logoImage) {
      try {
        await axiosAuth.delete(`${endpoint.baseUrl}/logoCompany`);
        setLogoImage(null);
        window.location.reload();
      } catch (error) {
        console.error("Error removing image", error);
      }
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <SpinnerDotted size={90} color="#a9ffff" />
      </div>
    );
  }

  return (
    <Container
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <SettingsPanel isXs={isXs} isSm={isSm} />
      <EmailsPanel
        emails={emails}
        setEmails={setEmails}
        isXs={isXs}
        isSm={isSm}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
        fetchEmails={fetchEmails}
      />
      <LogoPanel
        logoImage={logoImage}
        handleImageChange={handleImageChange}
        handleRemoveImage={handleRemoveImage}
        isXs={isXs}
        isSm={isSm}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
      />
      <CredentialsPanel
        userName={userName}
        isXs={isXs}
        isSm={isSm}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage.text}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default GeneralPageAdmin;
