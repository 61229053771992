import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { Box, Dialog, DialogContent } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useIsMobile from "./useIsMobile";

const InfoAdmin = ({
  text,
  imagePath,
  imageWidth,
  placementTooltip,
  blackText,
}) => {
  const [open, setOpen] = useState(false);
  const { isXs, isSm } = useIsMobile();
  const isMobile = isXs || isSm;

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={1}
      sx={{ margin: "40px 0px 10px 0px" }}
    >
      <Typography variant="h5" color={blackText ? "#000" : "#fff"}>
        {text}
      </Typography>
      {imagePath &&
        (isMobile ? (
          <>
            <InfoIcon
              onClick={handleToggle}
              style={{ cursor: "pointer", color: blackText ? "#000" : "#fff" }}
            />
            <Dialog open={open} onClose={handleClose}>
              <DialogContent sx={{ padding: 0 }}>
                <img
                  src={imagePath}
                  alt="Info"
                  style={{
                    width: "100%",
                  }}
                />
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <ClickAwayListener onClickAway={handleClose}>
            <Tooltip
              title={
                <img
                  src={imagePath}
                  alt="Info"
                  style={{
                    width: imageWidth,
                    border: "2px #000 solid",
                    borderRadius: 10,
                  }}
                />
              }
              placement={placementTooltip}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "transparent",
                    maxWidth: "fit-content",
                  },
                },
              }}
              arrow
            >
              <InfoIcon
                style={{
                  cursor: "pointer",
                  color: blackText ? "#000" : "#fff",
                }}
              />
            </Tooltip>
          </ClickAwayListener>
        ))}
    </Box>
  );
};

export default InfoAdmin;
