import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  TextField,
  Button,
  Paper,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import useIsMobile from "../../../../Assets/useIsMobile";
import ContactPageInstructions from "../components/ContactPageInstructions";
import { endpoint } from "../../../../endpoint";
import axiosAuth from "../../../../api/axiosConfig";
import { SpinnerDotted } from "spinners-react";

const ContactPageAdmin = () => {
  const { isXs, isSm } = useIsMobile();
  const [formValues, setFormValues] = useState({
    companyFullName: null,
    nip: null,
    regon: null,
    phone: null,
    email: null,
    zipCode: null,
    city: null,
    street: null,
    buildingNumber: null,
    flatNumber: null,
    googleMap: null,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState({
    text: "",
    severity: "",
  });

  const labels = {
    companyFullName: "Pełna nazwa firmy",
    nip: "NIP",
    regon: "REGON",
    phone: "Telefon",
    email: "Email",
    zipCode: "Kod pocztowy",
    city: "Miasto",
    street: "Ulica",
    buildingNumber: "Numer budynku",
    flatNumber: "Numer mieszkania",
    googleMap: "Google Map URL",
  };

  useEffect(() => {
    axios
      .get(`${endpoint.baseUrl}/contactPages`)
      .then((response) => {
        const { createdAt, updatedAt, ...rest } = response.data;
        setFormValues(rest);
      })
      .catch((error) =>
        console.error("Error loading the contact page data:", error)
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const prepareDataForSubmission = (data) => {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value === "" ? null : value,
      ])
    );
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (
      !formValues.companyFullName ||
      !formValues.nip ||
      !formValues.phone ||
      !formValues.email ||
      !formValues.zipCode ||
      !formValues.city ||
      !formValues.street ||
      !formValues.buildingNumber
    ) {
      setSnackbarMessage({
        text: "Uzupełnij pola",
        severity: "warning",
      });
      setSnackbarOpen(true);
      isValid = false;
      return isValid;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      newErrors.email = "Nieprawidłowy adres email";
      setSnackbarMessage({
        text: "Nieprawidłowy adres email",
        severity: "warning",
      });
      setSnackbarOpen(true);
      isValid = false;
    }

    if (!/^[+\d-]+$/.test(formValues.phone)) {
      newErrors.phone = "Nieprawidłowy numer telefonu";
      setSnackbarMessage({
        text: "Nieprawidłowy numer telefonu",
        severity: "warning",
      });
      setSnackbarOpen(true);
      isValid = false;
    }
    if (!/^\d{10}$/.test(formValues.nip)) {
      newErrors.nip = "Nieprawidłowy NIP";
      setSnackbarMessage({
        text: "Nieprawidłowy NIP",
        severity: "warning",
      });
      setSnackbarOpen(true);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const preparedData = prepareDataForSubmission(formValues);
    try {
      const response = await axiosAuth.put(
        `${endpoint.baseUrl}/contactPages`,
        preparedData
      );
      console.log("Update response:", response);
      setSnackbarMessage({
        text: "Dane zostały zaktualizowane",
        severity: "success",
      });
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating contact page data:", error);
      setSnackbarMessage({
        text: "Błąd podczas aktualizacji danych kontaktowych",
        severity: "error",
      });
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <SpinnerDotted size={90} color="#a9ffff" />
      </div>
    );
  }

  return (
    <Container
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Paper sx={{ padding: 4, width: isXs || isSm ? "100%" : 600 }}>
        <InfoAdmin
          text="Dane firmy"
          imagePath="/images/AdminPage/ContactPage/image1.jpg"
          imageWidth={isXs || isSm ? "90%" : 600}
          blackText
        />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} my={2}>
            {Object.entries(formValues).map(([key, value]) =>
              key !== "googleMap" && labels[key] ? (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    fullWidth
                    size="small"
                    label={labels[key]}
                    name={key}
                    value={value || ""}
                    onChange={handleChange}
                  />
                </Grid>
              ) : null
            )}
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            <Grid item xs={12}>
              <InfoAdmin
                text="Google maps"
                imagePath="/images/AdminPage/ContactPage/image2.jpg"
                imageWidth={isXs || isSm ? "90%" : 600}
                blackText
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={labels.googleMap}
                name="googleMap"
                value={formValues.googleMap || ""}
                onChange={handleChange}
              />

              {formValues.googleMap && (
                <div style={{ margin: "10px 0" }}>
                  <iframe
                    src={formValues.googleMap}
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen
                    title="Lokalizacja firmy"
                    loading="lazy"
                  ></iframe>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <ContactPageInstructions />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                sx={{
                  my: 2,
                  color: "#fff",
                  background: "linear-gradient(180deg, #121212,  #6d6d6de6 )",
                  fontWeight: 800,
                }}
              >
                Zapisz zmiany
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarMessage.severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage.text}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactPageAdmin;
