import React, { useEffect, useState } from "react";
import { Container, Snackbar, Alert } from "@mui/material";
import useIsMobile from "../../../../Assets/useIsMobile";
import axios from "axios";
import { endpoint } from "../../../../endpoint";
import WebAppsSection from "./WebAppsSection";
import ComputerServiceSection from "./ComputerServiceSection ";
import { SpinnerDotted } from "spinners-react";

const QuotationPageAdmin = () => {
  const [servicesCalculate, setServicesCalculate] = useState([]);
  const [services, setServices] = useState([]);
  const { isXs, isSm } = useIsMobile();
  const [newService, setNewService] = useState({ name: "", priceRange: "" });
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const fetchServicesCalculate = async () => {
    try {
      const response = await axios.get(
        `${endpoint.baseUrl}/quotationCalculate`
      );
      setServicesCalculate(response.data);
    } catch (error) {
      console.error("Błąd podczas pobierania danych:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${endpoint.baseUrl}/quotationList`);
      setServices(response.data);
    } catch (error) {
      console.error("Błąd podczas pobierania danych:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
    fetchServicesCalculate();
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <SpinnerDotted size={90} color="#a9ffff" />
      </div>
    );
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: isXs || isSm ? "100%" : 600,
        p: 0,
      }}
    >
      <WebAppsSection
        servicesCalculate={servicesCalculate}
        setServicesCalculate={setServicesCalculate}
        fetchServicesCalculate={fetchServicesCalculate}
        isXs={isXs}
        isSm={isSm}
        showSnackbar={showSnackbar}
      />
      <ComputerServiceSection
        services={services}
        setServices={setServices}
        newService={newService}
        setNewService={setNewService}
        fetchServices={fetchServices}
        isXs={isXs}
        isSm={isSm}
        showSnackbar={showSnackbar}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default QuotationPageAdmin;
