import React from "react";
import { Paper, Typography, Grid, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  box: {
    padding: 20,
    textAlign: "center",
    minHeight: 120,
    cursor: "pointer",
    background: "linear-gradient(180deg, #24f4e570, #050b13)",
    backgroundColor: "transparent !important",
    boxShadow: "0px 0px 5px 2px rgb(245 245 245 / 15%) !important",
    borderRadius: "20px !important",
    flexDirection: "column",
    width: "100%",
  },
  boxTitle: {
    fontWeight: "600 !important",
    color: "#fff",
  },
  boxDescription: {
    marginTop: "15px !important",
    fontSize: "14px !important",
    color: "#c9c9c9",
  },
});

const BoxHomePage = ({ title, description }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/offerPage", { state: { selectedTitle: title } });
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      onClick={handleClick}
      style={{ display: "flex", maxWidth: "100%" }}
    >
      <Paper className={classes.box} onClick={handleClick}>
        <Typography variant="h5" className={classes.boxTitle}>
          {title}
        </Typography>
        <Divider style={{ backgroundColor: "#fff", marginTop: 10 }} />
        <Typography className={classes.boxDescription}>
          {description}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default BoxHomePage;
