import { makeStyles } from "@mui/styles";

const QuotationPageStyles = makeStyles({
  body: {
    fontSize: 20,
    flexGrow: 1,
    padding: "25px 150px !important",
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#fefefe05",
    boxShadow: "0px 0px 5px 2px rgb(245 245 245 / 15%)",
  },
  bodyMobile: {
    fontSize: 20,
    padding: "80px 10px",
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    background: "linear-gradient(180deg, #03090ed4, #3bfff02e)",
  },
  customTab: {
    fontSize: "20px !important",
    color: "#fff !important",
    fontWeight: "600 !important",
    fontStyle: "inherit !important",
    transition: "transform 0.3s ease-in-out !important",
    "&:hover": {
      transform: "translateY(-8px)",
    },
    "&.Mui-selected": {
      transform: "translateY(-8px)",
      color: "#fff !important",
      fontWeight: "bold !important",
    },
  },
  customTabMobile: {
    fontSize: "13px !important",
    color: "#fff !important",
    fontWeight: "600 !important",
    fontStyle: "inherit !important",
    "&:hover": {
      fontSize: "13px !important",
    },
    "&.Mui-selected": {
      color: "#fff !important",
      fontWeight: "bold !important",
    },
  },

  tabIndicator: {
    backgroundColor: "#fff !important",
  },
  priceCalculator: {
    marginTop: 20,
  },

  inputWhite: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
    "& .MuiInputBase-input": {
      color: "#fff ",
    },
    "& .MuiInputLabel-root": {
      color: "#fff !important",
    },
  },

  labelWhite: {
    color: "#fff",
  },
});

export default QuotationPageStyles;
