import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { styled } from "@mui/system";

const ScrollBox = styled(Box)({
  maxHeight: 550,
  overflowY: "auto",
  paddingRight: "10px",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
});

const PrivacyPolicyContent = () => {
  return (
    <Paper
      elevation={3}
      style={{
        padding: "20px",
        margin: "20px",
        boxShadow: "none",
        backgroundColor: "transparent",
        color: "#fff",
      }}
    >
      <ScrollBox>
        <Typography variant="h4" gutterBottom>
          Polityka prywatności
        </Typography>

        <Typography variant="h6" gutterBottom>
          I. Postanowienia ogólne
        </Typography>
        <Typography paragraph>
          Polityka prywatności określa, jak zbierane, przetwarzane i
          przechowywane są dane osobowe Użytkowników niezbędne do świadczenia
          usług drogą elektroniczną za pośrednictwem serwisu internetowego
          https://www.hestech.pl/ (dalej: Serwis).
        </Typography>
        <Typography paragraph>
          Serwis zbiera wyłącznie dane osobowe niezbędne do świadczenia i
          rozwoju usług w nim oferowanych.
        </Typography>
        <Typography paragraph>
          Dane osobowe zbierane za pośrednictwem Serwisu są przetwarzane zgodnie
          z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
          27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
          o ochronie danych, dalej RODO) oraz ustawą o ochronie danych osobowych
          z dnia 10 maja 2018 r.
        </Typography>

        <Typography variant="h6" gutterBottom>
          II. Administrator danych
        </Typography>
        <Typography paragraph>
          Administratorem danych osobowych zbieranych poprzez Serwis jest
          Digital HesTech - Damian Hyski, adres: Tęczowa 50, 29-100 Czarnca,
          Polska, NIP: 6090084847, adres poczty elektronicznej:
          hyski12@gmail.com (dalej: Administrator).
        </Typography>

        <Typography variant="h6" gutterBottom>
          III. Cel zbierania danych osobowych
        </Typography>
        <Typography paragraph>
          Dane osobowe wykorzystywane są w celu:
          <ul>
            <li>
              komunikacji z Użytkownikiem za pośrednictwem formularza
              kontaktowego,
            </li>
            <li>odpowiedzi na zapytania Użytkowników,</li>
            <li>świadczenia usług drogą elektroniczną.</li>
          </ul>
        </Typography>
        <Typography paragraph>
          Podanie danych jest dobrowolne, ale niezbędne do skorzystania z
          formularza kontaktowego.
        </Typography>

        <Typography variant="h6" gutterBottom>
          IV. Rodzaj przetwarzanych danych osobowych
        </Typography>
        <Typography paragraph>
          Administrator może przetwarzać dane osobowe Użytkownika: imię i
          nazwisko, adres e-mail.
        </Typography>

        <Typography variant="h6" gutterBottom>
          V. Okres przetwarzania danych osobowych
        </Typography>
        <Typography paragraph>
          Dane osobowe Użytkowników będą przechowywane na serwerze do momentu
          ich usunięcia z bazy danych przez Administratora.
        </Typography>

        <Typography variant="h6" gutterBottom>
          VI. Udostępnianie danych osobowych
        </Typography>
        <Typography paragraph>
          Dane osobowe Użytkowników są dostępne jedynie dla Administratora i
          upoważnionych przez niego osób (administratorów).
        </Typography>

        <Typography variant="h6" gutterBottom>
          VII. Prawa Użytkowników
        </Typography>
        <Typography paragraph>
          Użytkownik Serwisu ma prawo do: dostępu do treści swoich danych
          osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania,
          przenoszenia, wniesienia sprzeciwu wobec przetwarzania, cofnięcia
          zgody w każdej chwili (co nie ma wpływu na zgodność z prawem
          przetwarzania dokonanego w oparciu o zgodę przed jej cofnięciem).
        </Typography>
        <Typography paragraph>
          Zgłoszenie o wystąpieniu przez Użytkownika z uprawnieniem wynikającym
          z wymienionych praw należy przesłać na adres: hyski12@gmail.com.
        </Typography>
        <Typography paragraph>
          Administrator spełnia lub odmawia spełnienia żądania niezwłocznie –
          maksymalnie w ciągu miesiąca od jego otrzymania.
        </Typography>
        <Typography paragraph>
          Użytkownik ma prawo złożyć skargę do Prezesa Urzędu Ochrony Danych
          Osobowych, jeśli uzna, że przetwarzanie narusza jego prawa i wolności
          (RODO).
        </Typography>

        <Typography variant="h6" gutterBottom>
          VIII. Środki bezpieczeństwa
        </Typography>
        <Typography paragraph>
          Administrator stosuje odpowiednie środki techniczne i organizacyjne w
          celu ochrony danych osobowych, w szczególności przechowuje dane na
          bezpiecznej bazie danych heroku postgrs.
        </Typography>

        <Typography variant="h6" gutterBottom>
          IX. Postanowienia końcowe
        </Typography>
        <Typography paragraph>
          Administrator ma prawo do wprowadzenia zmian w Polityce prywatności,
          przy czym prawa Użytkowników nie zostaną ograniczone.
        </Typography>
        <Typography paragraph>
          Informacja o wprowadzonych zmianach pojawi się w formie komunikatu
          dostępnego w Serwisie.
        </Typography>
        <Typography paragraph>
          W sprawach nieuregulowanych w niniejszej Polityce prywatności
          obowiązują przepisy RODO i przepisy prawa polskiego.
        </Typography>
      </ScrollBox>
    </Paper>
  );
};

export default PrivacyPolicyContent;
