import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { endpoint } from "../../endpoint";
import ContactPageStyles from "./ContactPageStyles";
import { SpinnerDotted } from "spinners-react";
import { useSpring, animated } from "@react-spring/web"; // Zmiana importu
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const ContactPage = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);
  const classes = ContactPageStyles();
  const [contactInfo, setContactInfo] = useState({
    companyFullName: null,
    zipCode: null,
    city: null,
    street: null,
    buildingNumber: null,
    flatNumber: null,
    email: null,
    phone: null,
    nip: null,
    regon: null,
    googleMap: null,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    title: "",
    message: "",
    consent: false,
  });

  const companyInfoSpring = useSpring({
    opacity: loading ? 0 : 1,
    transform: loading ? "translateX(-100%)" : "translateX(0px)",
    from: { opacity: 0, transform: "translateX(-100%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const formSpring = useSpring({
    opacity: loading ? 0 : 1,
    transform: loading ? "translateX(100%)" : "translateX(0px)",
    from: { opacity: 0, transform: "translateX(100%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const mapSpring = useSpring({
    opacity: loading ? 0 : 1,
    transform: loading ? "translateY(50%)" : "translateY(0px)",
    from: { opacity: 0, transform: "translateY(50%)" },
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await axios.get(`${endpoint.baseUrl}/contactPages`);
        setContactInfo(response.data);
      } catch (error) {
        console.error("Failed to fetch contact info", error);
      } finally {
        setLoading(false);
      }
    };
    fetchContactInfo();
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name) {
      setSnackbarMessage("Uzupełnij pola");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    } else if (formData.name.length > 50) {
      setSnackbarMessage("Imię i nazwisko nie może przekraczać 50 znaków");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    }

    if (!formData.email) {
      setSnackbarMessage("Uzupełnij pola");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    } else if (!emailRegex.test(formData.email)) {
      setSnackbarMessage("Nieprawidłowy adres email");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    }

    if (!formData.title) {
      setSnackbarMessage("Uzupełnij pola");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    } else if (formData.title.length > 100) {
      setSnackbarMessage("Temat nie może przekraczać 100 znaków");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    }

    if (!formData.message) {
      setSnackbarMessage("Uzupełnij pola");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    } else if (formData.message.length > 1500) {
      setSnackbarMessage("Wiadomość nie może przekraczać 1500 znaków");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    }

    if (!formData.consent) {
      setSnackbarMessage(
        "Musisz wyrazić zgodę na przetwarzanie danych osobowych"
      );
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    const payload = {
      name: formData.name,
      email: formData.email,
      title: formData.title,
      text: formData.message,
      is_read: false,
    };

    try {
      await axios.post(`${endpoint.baseUrl}/emails`, payload);
      setFormData({ name: "", email: "", title: "", message: "" });
      setSnackbarMessage("Wiadomość została wysłana!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error sending message", error);
      setSnackbarMessage("Błąd podczas wysyłania wiadomości.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <SpinnerDotted size={90} color="#a9ffff" />
        </div>
      )}
      <Container
        className={classes.body}
        style={{ display: loading ? "none" : "" }}
      >
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} fullWidth>
              <animated.div style={companyInfoSpring}>
                <Paper elevation={3} className={classes.companyInfo}>
                  <Typography variant="h6" color={"#fff"}>
                    {contactInfo.companyFullName}
                  </Typography>
                  <Typography sx={{ marginTop: 2 }} color={"#fff"}>
                    {`${contactInfo.zipCode} ${contactInfo.city}`}
                  </Typography>
                  <Typography color={"#fff"}>
                    {`${contactInfo.street} ${contactInfo.buildingNumber}${
                      contactInfo.flatNumber ? `/${contactInfo.flatNumber}` : ""
                    }`}
                  </Typography>
                  <Typography
                    color={"#fff"}
                    sx={{ marginTop: 1, display: "flex", gap: "3px" }}
                  >
                    Email:
                    <a
                      style={{
                        color: "#fff",
                        textDecoration: "none",
                        fontWeight: 600,
                        display: "flex",
                        gap: 8,
                      }}
                      href={`mailto:${contactInfo.email}`}
                    >
                      {contactInfo.email}
                      <OpenInNewIcon />
                    </a>
                  </Typography>
                  <Typography
                    color={"#fff"}
                    sx={{ marginTop: 1, display: "flex", gap: "3px" }}
                  >
                    Telefon:
                    <a
                      style={{
                        color: "#fff",
                        textDecoration: "none",
                        fontWeight: 600,
                        display: "flex",
                        gap: 8,
                      }}
                      href={`tel:${contactInfo.phone}`}
                    >
                      {contactInfo.phone}
                      <OpenInNewIcon />
                    </a>
                  </Typography>

                  <Typography
                    color={"#fff"}
                    sx={{ marginTop: 1 }}
                  >{`NIP: ${contactInfo.nip}`}</Typography>

                  {contactInfo?.regon && (
                    <Typography
                      sx={{ marginTop: 1 }}
                    >{`REGON: ${contactInfo.regon}`}</Typography>
                  )}
                </Paper>
              </animated.div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              fullWidth
              sx={{ display: "grid", gap: 2, marginTop: 1 }}
            >
              <animated.div style={formSpring}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Imię i nazwisko"
                  name="name"
                  size="small"
                  value={formData.name}
                  onChange={handleChange}
                  className={classes.inputWhite}
                />
              </animated.div>
              <animated.div style={formSpring}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Adres email"
                  type="email"
                  name="email"
                  size="small"
                  value={formData.email}
                  onChange={handleChange}
                  className={classes.inputWhite}
                />
              </animated.div>
              <animated.div style={formSpring}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Temat"
                  name="title"
                  size="small"
                  value={formData.title}
                  onChange={handleChange}
                  className={classes.inputWhite}
                />
              </animated.div>
              <animated.div style={formSpring}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Wiadomość"
                  multiline
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className={classes.inputWhite}
                />
              </animated.div>
              <animated.div style={formSpring}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.consent}
                      onChange={handleCheckboxChange}
                      name="consent"
                      sx={{
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#fff",
                        },
                      }}
                    />
                  }
                  label="Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z Polityką prywatności"
                  sx={{ color: "#fff" }}
                />
              </animated.div>
              <animated.div style={formSpring}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                >
                  Wyślij
                </Button>
              </animated.div>
            </Grid>

            <Grid item xs={12} sm={8} fullWidth>
              <animated.div style={mapSpring}>
                <div className={classes.mapContainer}>
                  <iframe
                    src={contactInfo.googleMap}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    title="Location Map"
                    allowFullScreen
                  ></iframe>
                </div>
              </animated.div>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default ContactPage;
