import { configureStore } from "@reduxjs/toolkit";

const counterReducer = (state = { counter: 0 }, action) => {
  switch (action.type) {
    case "increment":
      return { ...state, counter: state.counter + 1 };
    default:
      return state;
  }
};

const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
});

export default store;
