import { makeStyles } from "@mui/styles";

const AboutPageStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: "70px 150px !important",
    backgroundColor: "#fefefe05",
    boxShadow: "0px 0px 5px 2px rgb(245 245 245 / 15%)",
    display: "flex !important",
    flexDirection: "column",
  },
  rootMobile: {
    padding: "100px 30px !important",
    display: "flex !important",
    flexDirection: "column",
    minHeight: "93vh",
    textAlign: "justify",
  },
  section: {
    marginBottom: 20,
  },
  typography: {
    textAlign: "justify",
    color: "#fff",
  },
});

export default AboutPageStyles;
