import React, { useState } from "react";
import { Paper, Button, IconButton, Box } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import { endpoint } from "../../../../endpoint";
import ConfirmDelete from "../components/ConfirmDelete";

const LogoPanel = ({
  logoImage,
  handleImageChange,
  handleRemoveImage,
  isXs,
  isSm,
  setSnackbarMessage,
  setSnackbarOpen,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleRemoveImageClick = () => {
    setConfirmOpen(true);
  };

  const handleConfirmRemoveImage = async () => {
    try {
      await handleRemoveImage();
    } catch (error) {
      setSnackbarMessage({
        text: "Błąd przy usuwaniu obrazu",
        severity: "error",
      });
      setSnackbarOpen(true);
    } finally {
      setSnackbarMessage({
        text: "Obraz został usunięty",
        severity: "success",
      });
      setSnackbarOpen(true);
    }
    setConfirmOpen(false);
  };

  const handleImageAdd = async (event) => {
    try {
      await handleImageChange(event);
    } catch (error) {
      setSnackbarMessage({
        text: "Błąd przy dodawaniu obrazu",
        severity: "error",
      });
      setSnackbarOpen(true);
    } finally {
      setSnackbarMessage({ text: "Obraz został dodany", severity: "success" });
      setSnackbarOpen(true);
    }
  };
  
  return (
    <>
      <InfoAdmin
        text="Logo firmy"
        imagePath="/images/AdminPage/GeneralPage/image1.jpg"
        imageWidth={isXs || isSm ? "90%" : 600}
      />
      <Paper
        sx={{
          mb: 4,
          width: isXs || isSm ? "100%" : 600,
          textAlign: "center",
          padding: 4,
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            alignSelf: "center",
          }}
        >
          {logoImage ? (
            <>
              <img
                src={
                  logoImage.imagePath && logoImage.imagePath.includes("blob:")
                    ? logoImage.imagePath
                    : `${endpoint.baseUrl}/${logoImage.imagePath}`
                }
                alt="Logo"
                style={{
                  width: isXs || isSm ? "100%" : 350,
                  objectFit: "cover",
                }}
              />
              <IconButton
                aria-label="delete"
                onClick={handleRemoveImageClick}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 35,
                  backgroundColor: "#fff",
                  color: "#000",
                  padding: "2px",
                  transition: "transform 0.3s ease-in-out !important",
                  "&:hover": {
                    backgroundColor: "#fff !important",
                    color: "#000 !important",
                    transform: "scale(0.9)",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <Button
              component="label"
              sx={{ padding: 2, border: "2px #000 solid", color: "#000" }}
            >
              <AddPhotoAlternateIcon />
              <input type="file" hidden onChange={handleImageAdd} />
            </Button>
          )}
        </Box>
      </Paper>

      <ConfirmDelete
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirmRemoveImage}
        title="Czy na pewno chcesz usunąć logo?"
      />
    </>
  );
};

export default LogoPanel;
