import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Header from "./Components/Header";
import AdminPanelPage from "./Pages/AdminPanelPage/AdminPanelPage";
import LoginAdminPage from "./Pages/AdminPanelPage/LoginAdminPage";
import axios from "axios";
import KnowledgePage from "./Pages/KnowledgePage/KnowledgePage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import HomePage from "./Pages/HomePage/HomePage";
import OfferPage from "./Pages/OfferPage/OfferPage";
import QuotationPage from "./Pages/QuotationPage/QuotationPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import FilesPage from "./Pages/FilesPage/FilesPage";
import { endpoint } from "./endpoint";
import { useAdminCheck } from "./api/useAdminCheck";
import { SpinnerDotted } from "spinners-react";
import Footer from "./Components/Footer";

const App = () => {
  const { isAdmin } = useAdminCheck();
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  const [imageLoadingLogo, setImageLoadingLogo] = useState(true);
  const [pageSettings, setPageSettings] = useState({
    offerPage: true,
    quotationPage: true,
    aboutPage: true,
    contactPage: true,
    filesPage: true,
    knowledgePage: true,
  });

  useEffect(() => {
    const fetchPageSettings = async () => {
      try {
        const settingsResponse = await axios.get(
          `${endpoint.baseUrl}/settingsPages`
        );
        setPageSettings(settingsResponse.data);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPageSettings();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && videoRef.current) {
        videoRef.current.play();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Router>
      {(imageLoadingLogo || loading || isAdmin === null) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <SpinnerDotted size={90} color="#a9ffff" />
        </div>
      )}
      <div
        className="App"
        style={{
          display:
            imageLoadingLogo || loading || isAdmin === null ? "none" : "",
        }}
      >
        <Header setImageLoadingLogo={setImageLoadingLogo} />
        <MainContent pageSettings={pageSettings} isAdmin={isAdmin} />
        <Footer />
      </div>
    </Router>
  );
};

const MainContent = ({ pageSettings, isAdmin }) => {
  return (
    <Routes>
      <Route path="/homePage" element={<HomePage />} />
      {pageSettings.offerPage && (
        <Route path="/offerPage" element={<OfferPage />} />
      )}
      {pageSettings.aboutPage && (
        <Route path="/aboutPage" element={<AboutPage />} />
      )}
      {pageSettings.quotationPage && (
        <Route path="/quotationPage" element={<QuotationPage />} />
      )}
      {pageSettings.contactPage && (
        <Route path="/contactPage" element={<ContactPage />} />
      )}
      {pageSettings.knowledgePage && (
        <Route path="/knowledgePage" element={<KnowledgePage />} />
      )}
      {pageSettings.filesPage && (
        <Route path="/filesPage" element={<FilesPage />} />
      )}
      <Route
        path="/adminPage"
        element={
          isAdmin ? <AdminPanelPage /> : <Navigate to="/homePage" replace />
        }
      />
      <Route
        path="/loginAdminPage"
        element={
          !isAdmin ? <LoginAdminPage /> : <Navigate to="/adminPage" replace />
        }
      />
      <Route path="*" element={<Navigate to="/homePage" replace />} />
    </Routes>
  );
};

export default App;
