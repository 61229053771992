import { makeStyles } from "@mui/styles";

const FilePageStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: "100px 150px !important",
    height: "93vh",
    display: "flex !important",
    flexDirection: "column",
    color: "#fff",
    alignItems: "center",
    gap: 40,
  },
  rootMobile: {
    flexGrow: 1,
    padding: "100px 14px !important",
    height: "93vh",
    display: "flex !important",
    flexDirection: "column",
    color: "#fff",
    alignItems: "center",
    gap: 40,
  },
  typography: {
    textAlign: "justify",
    color: "#fff",
  },
  listItem: {
    borderBottom: "1px solid #ffffff66",
    display: "flex",
    maxWidth: 900,
    width: "100%",
  },
});

export default FilePageStyles;
