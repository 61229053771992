import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import axiosAuth from "../../../../api/axiosConfig";
import { endpoint } from "../../../../endpoint";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmDelete from "../components/ConfirmDelete";

const WebAppsSection = ({
  servicesCalculate,
  setServicesCalculate,
  fetchServicesCalculate,
  isXs,
  isSm,
  showSnackbar,
}) => {
  const [changes, setChanges] = useState({});
  const [newService, setNewService] = useState({
    name: "",
    priceRange: "",
    isMobile: "",
    pricePerLanguage: "",
    forInvaid: "",
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  const webAppLabels = {
    priceRange: "Cena za stronę",
    isMobile: "Cena za wersję mobilną",
    pricePerLanguage: "Cena za dodatkowy język",
    forInvaid: "Dostosowanie dla niepełnosprawnych",
  };

  const validateService = (service) => {
    if (!service.name) {
      showSnackbar("Nazwa nie może być pusta", "warning");
      return false;
    }
    for (const key of Object.keys(webAppLabels)) {
      if (!service[key]) {
        showSnackbar(`Pola nie mogą być puste`, "warning");
        return false;
      }
      if (isNaN(service[key])) {
        showSnackbar(`Pole "${webAppLabels[key]}" musi być liczbą`, "warning");
        return false;
      }
    }
    return true;
  };

  const handleCalculateServiceChange = (id, field, value) => {
    const updatedServices = servicesCalculate.map((service) => {
      if (service.id === id) {
        return { ...service, [field]: value };
      }
      return service;
    });
    setServicesCalculate(updatedServices);

    setChanges((prevChanges) => ({
      ...prevChanges,
      [id]: true,
    }));
  };

  const handleNewServiceChange = (field, value) => {
    setNewService((prevService) => ({
      ...prevService,
      [field]: value,
    }));
  };

  const saveServiceCalculate = async (id, updatedService) => {
    if (!validateService(updatedService)) return;
    try {
      await axiosAuth.put(
        `${endpoint.baseUrl}/quotationCalculate/${id}`,
        updatedService
      );
      fetchServicesCalculate();
      showSnackbar("Zaktualizowano element!", "success");
      setChanges((prevChanges) => ({
        ...prevChanges,
        [id]: false,
      }));
    } catch (error) {
      console.error("Błąd podczas zapisywania danych:", error);
      showSnackbar("Błąd podczas zapisywania danych", "error");
    }
  };

  const handleSaveCalculate = (service) => {
    saveServiceCalculate(service.id, service);
  };

  const handleDeleteWebApp = async (id) => {
    try {
      await axiosAuth.delete(`${endpoint.baseUrl}/quotationCalculate/${id}`);
      fetchServicesCalculate();
      showSnackbar("Usunięto element!", "success");
    } catch (error) {
      console.error("Błąd podczas usuwania elementu:", error);
      showSnackbar("Błąd podczas usuwania elementu", "error");
    }
    setOpenDeleteModal(false);
  };

  const handleAddNewWebApp = async () => {
    if (!validateService(newService)) return;
    try {
      await axiosAuth.post(
        `${endpoint.baseUrl}/quotationCalculate`,
        newService
      );
      fetchServicesCalculate();
      showSnackbar("Dodano nowy element!", "success");
      setNewService({
        name: "",
        priceRange: "",
        isMobile: "",
        pricePerLanguage: "",
        forInvaid: "",
      });
    } catch (error) {
      console.error("Błąd podczas dodawania nowego elementu:", error);
      showSnackbar("Błąd podczas dodawania nowego elementu", "error");
    }
  };

  const openDeleteConfirmation = (service, index) => {
    setServiceToDelete({ ...service, index });
    setOpenDeleteModal(true);
  };

  const closeDeleteConfirmation = () => {
    setServiceToDelete(null);
    setOpenDeleteModal(false);
  };

  const confirmDelete = () => {
    if (serviceToDelete) {
      handleDeleteWebApp(serviceToDelete.id);
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <InfoAdmin
        text="Aplikacje webowe"
        imagePath="/images/AdminPage/QuotationPage/image1.jpg"
        imageWidth={isXs || isSm ? "90%" : 600}
      />
      <Paper elevation={3} sx={{ p: 2 }}>
        {servicesCalculate.map((service, index) => (
          <Box key={service.id} sx={{ mb: 2 }}>
            <Typography
              variant="h6"
              sx={{ alignSelf: "center", textAlign: "center" }}
            >
              {`#${index + 1}`}
            </Typography>
            <Grid sx={{ padding: "20px 0" }}>
              <TextField
                size="small"
                fullWidth
                value={service.name}
                label="Nazwa"
                onChange={(e) =>
                  handleCalculateServiceChange(
                    service.id,
                    "name",
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid container spacing={2}>
              {Object.keys(webAppLabels).map((key) => (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    fullWidth
                    size="small"
                    label={webAppLabels[key]}
                    variant="outlined"
                    value={service[key]}
                    onChange={(e) =>
                      handleCalculateServiceChange(
                        service.id,
                        key,
                        e.target.value
                      )
                    }
                  />
                </Grid>
              ))}
            </Grid>

            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => handleSaveCalculate(service)}
                style={{
                  color: changes[service.id] ? "#227722" : "#00000042",
                }}
                disabled={!changes[service.id]}
              >
                <SaveIcon sx={{ fontSize: 30, padding: 0 }} />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => openDeleteConfirmation(service, index)}
                sx={{ color: "#ad1f1f", marginLeft: "auto" }}
              >
                <DeleteForeverIcon sx={{ fontSize: 30, padding: 0 }} />
              </IconButton>
            </div>
            <Divider sx={{ my: 2 }} />
          </Box>
        ))}
        <Box sx={{ mb: 2, mt: 4 }}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Nowa aplikacja webowa
          </Typography>
          <Grid sx={{ padding: "20px 0" }}>
            <TextField
              size="small"
              fullWidth
              value={newService.name}
              label="Nazwa"
              onChange={(e) => handleNewServiceChange("name", e.target.value)}
            />
          </Grid>
          <Grid container spacing={2}>
            {Object.keys(webAppLabels).map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  fullWidth
                  size="small"
                  label={webAppLabels[key]}
                  variant="outlined"
                  value={newService[key]}
                  onChange={(e) => handleNewServiceChange(key, e.target.value)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleAddNewWebApp}
          sx={{
            my: 2,
            color: "#fff",
            background: "linear-gradient(180deg, #121212,  #6d6d6de6 )",
            fontWeight: 800,
          }}
        >
          Dodaj nową aplikację webową
        </Button>
      </Paper>
      <ConfirmDelete
        open={openDeleteModal}
        onClose={closeDeleteConfirmation}
        onConfirm={confirmDelete}
        title={
          serviceToDelete
            ? `Czy na pewno chcesz usunąć ten element #${
                serviceToDelete.index + 1
              } ?`
            : ""
        }
      />
    </Box>
  );
};

export default WebAppsSection;
