import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import axios from "axios";
import { endpoint } from "../../endpoint";

const LoginAdminPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${endpoint.baseUrl}/auth/login`, {
        username,
        password,
      });
      const { token } = response.data;
      localStorage.setItem("authToken", token);
      window.location.reload();
      navigate("/adminPage");
    } catch (error) {
      console.error("Login failed:", error.response.data);
      setSnackbarMessage("Błędne dane");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: "93vh",
        backgroundColor: "#ffffffe6",
        boxShadow: "0px 0px 6px 1px rgb(0 0 0 / 37%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: 600, display: "flex", gap: 1 }}
        >
          <AdminPanelSettingsIcon sx={{ fontSize: 30 }} />
          Panel administratora
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
          <TextField
            margin="normal"
            fullWidth
            id="username"
            label="Nazwa"
            name="username"
            autoComplete="username"
            autoFocus
            size="small"
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Hasło"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            size="small"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            sx={{
              color: "#fff",
              background: "linear-gradient(180deg, #121212,  #6d6d6de6 )",
              fontWeight: 800,
              my: 3,
            }}
          >
            Zaloguj się
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginAdminPage;
